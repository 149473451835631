import React, { useMemo } from 'react';
import { type Entity } from '@backstage/catalog-model';
import { Divider, IconButton, Card, makeStyles } from '@material-ui/core';
import {
  type Tag,
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
} from '@internal/backstage-plugin-adeo-core-components-react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Alert } from '@material-ui/lab';

import { getLifecycleTag } from '../../../../../../utils/getLifecycleTag';
import { useEntityRoute } from '../../../../../../hooks/useEntityRoute';
import { useMetrics } from '../../../../../../hooks/metrics/useMetrics';
import { useNavigate } from 'react-router';

export type PackageEntityProductCardProps = {
  entity: Entity;
};

const usePackageEntityProductCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  errorContainer: {
    margin: theme.spacing(2),
  },
}));

export const PackageEntityProductCard = (
  props: PackageEntityProductCardProps,
) => {
  const { entity } = props;
  const entityRoute = useEntityRoute(entity);
  const classes = usePackageEntityProductCardStyles();
  const navigate = useNavigate();

  const { metrics, loading, error } = useMetrics(entity, [
    'owner',
    'namespace',
  ]);

  const type = useMemo(() => entity.spec?.type?.toString() ?? '', [entity]);

  const lifecycle = entity.spec?.lifecycle?.toString() ?? '';
  const tags: Tag[] = [getLifecycleTag(lifecycle)];

  if (!entityRoute) {
    throw new Error('Entity route not found');
  }

  const handleClick = () => {
    navigate(entityRoute);
  };

  const button = (
    <IconButton onClick={handleClick}>
      <ArrowForward />
    </IconButton>
  );

  return (
    <Card>
      <EntityPresentation size="small">
        <div className={classes.content}>
          <EntityPresentationHeader
            title={entity.metadata.title ?? 'No title'}
            type={type}
            tags={tags}
            actionButton={button}
            targetLink={entityRoute}
          />
          <EntityPresentationDescription
            description={entity.metadata.description ?? 'No description'}
          />
        </div>
        <Divider />
        {error ? (
          <Alert severity="error" className={classes.errorContainer}>
            {error.message}
          </Alert>
        ) : (
          <EntityPresentationMetrics metrics={metrics} loading={loading} />
        )}
      </EntityPresentation>
    </Card>
  );
};

PackageEntityProductCard.displayName = 'PackageEntityProductCard';
