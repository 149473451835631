import { type Entity, type CompoundEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';

export const useEntityRoute = (
  entity?: Entity | CompoundEntityRef | undefined,
) => {
  const generateEntityRoute = useRouteRef(entityRouteRef);

  if (!entity) {
    return undefined;
  }

  if ('metadata' in entity) {
    return generateEntityRoute({
      kind: entity.kind,
      namespace: entity.metadata.namespace ?? 'No namespace',
      name: entity.metadata.name,
    });
  }
  return generateEntityRoute({
    kind: entity.kind,
    namespace: entity.namespace,
    name: entity.name,
  });
};
