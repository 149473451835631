import React from 'react';
import {
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  Container,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { useRouteRef } from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { LinkButton, LinkButtonProps } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { AddTaskIcon } from 'backstage-plugin-icons-react';
import { PackageEntityProductCard } from './PackageEntityProductCard';
import { EntityWarningContent } from '../../../../content/EntityWarningContent';
import { useMetrics } from '../../../../../../hooks/metrics/useMetrics';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

export const SubscribeButton = (props: Omit<LinkButtonProps, 'to'>) => {
  const { entity } = useEntity();
  const { disabled } = props;

  const scaffolderTemplateRoute = useRouteRef(
    scaffolderPlugin.routes.selectedTemplate,
  );

  const createSubscriptionUrl = `${scaffolderTemplateRoute({
    namespace: 'default',
    templateName: 'subscription-template',
  })}?formData={"package":"${stringifyEntityRef(entity)}"}`;

  if (disabled) {
    return (
      <CustomTooltip
        title="You cannot subscribe to this package until all the components are initialized and do not have at least one release."
        arrow
      >
        <div>
          <LinkButton
            to={createSubscriptionUrl}
            variant="contained"
            color="primary"
            startIcon={<AddTaskIcon />}
            {...props}
          >
            Subscribe
          </LinkButton>
        </div>
      </CustomTooltip>
    );
  }

  return (
    <LinkButton
      to={createSubscriptionUrl}
      variant="contained"
      color="primary"
      startIcon={<AddTaskIcon />}
      {...props}
    >
      Subscribe
    </LinkButton>
  );
};

const usePackageEntityOverviewStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },
  entityPresentation: {
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const PackageEntityOverview = () => {
  const classes = usePackageEntityOverviewStyles();
  const { entity } = useEntity();

  const { metrics, loading, error } = useMetrics(entity, [
    'owner',
    'namespace',
    'products',
  ]);

  const { entities: dependsOnRelations } = useRelatedEntities(entity, {
    kind: 'component',
    type: RELATION_DEPENDS_ON,
  });

  const type = entity?.spec?.type?.toString() ?? '';

  // if all entities have a lifecycle as ready, then the subscribe button should be accessible
  const subscribeButtonDisabled = dependsOnRelations?.some(
    e => e.spec?.lifecycle !== 'ready',
  );

  return (
    <Container maxWidth="lg" className={classes.root}>
      {EntityWarningContent}
      <EntityPresentation size="medium" className={classes.entityPresentation}>
        <div className={classes.actionButton}>
          <SubscribeButton disabled={subscribeButtonDisabled} />
        </div>
        <EntityPresentationHeader
          title={entity.metadata.title ?? ''}
          type={type}
        />
        <EntityPresentationDescription
          description={entity.metadata.description ?? ''}
        />
        <Divider />
        {error ? (
          <Alert severity="error" className={classes.errorContainer}>
            {error.message}
          </Alert>
        ) : (
          <EntityPresentationMetrics metrics={metrics} loading={loading} />
        )}
        <Divider />
      </EntityPresentation>

      {dependsOnRelations && dependsOnRelations.length > 0 && (
        <Grid container spacing={2}>
          {dependsOnRelations.map(onDemandsEntity => (
            <Grid
              item
              key={onDemandsEntity.metadata.uid}
              xs={12}
              sm={12}
              md={12}
              lg={6}
            >
              <PackageEntityProductCard entity={onDemandsEntity} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

PackageEntityOverview.displayName = 'PackageEntityOverview';
