import React, { createContext, forwardRef, useContext, useMemo } from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  type BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@material-ui/core';

export type BreadcrumbsProps = MuiBreadcrumbsProps & {
  isExpandable?: boolean;
};

const BreadcrumbsContext = createContext({
  isExpandable: true,
});

export const useBreadcrumbsContext = () => {
  return useContext(BreadcrumbsContext);
};

export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>(
  (props, ref) => {
    const { children, isExpandable = true, ...rest } = props;
    const value = useMemo(() => ({ isExpandable }), [isExpandable]);
    return (
      <BreadcrumbsContext.Provider value={value}>
        <MuiBreadcrumbs ref={ref} {...rest}>
          {children}
        </MuiBreadcrumbs>
      </BreadcrumbsContext.Provider>
    );
  },
);

Breadcrumbs.displayName = 'Breadcrumbs';
