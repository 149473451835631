import React from 'react';
import { PackageEntityBreadcrumb } from './PackageEntityBreadcrumb';
import { ProductRefEntityBreadcrumb } from './ProductRefEntityBreadcrumb';
import { ProductVersionEntityBreadcrumb } from './ProductVersionEntityBreadcrumb';
import { GroupEntityBreadcrumb } from './GroupEntityBreadcrumb';
import { UserEntityBreadcrumb } from './UserEntityBreadcrumb';
import { Entity } from '@backstage/catalog-model';
import { ComponentAndResourceEntityBreadcrumb } from './ComponentAndResourceEntityBreadcrumb';

export const getBreadcrumb = (entity?: Entity): React.FC | null => {
  const key =
    entity?.kind === 'Component'
      ? `${entity.kind}:${entity.spec?.type}`
      : entity?.kind;

  // eslint-disable-next-line default-case
  switch (key) {
    case 'Component:package':
      return PackageEntityBreadcrumb;
    case 'Component:product':
      return ProductRefEntityBreadcrumb;
    case 'Component:productVersion':
      return ProductVersionEntityBreadcrumb;
    case 'Resource':
      return ComponentAndResourceEntityBreadcrumb;
    case 'Group':
      return GroupEntityBreadcrumb;
    case 'User':
      return UserEntityBreadcrumb;
  }

  if (entity?.kind === 'Component') {
    return ComponentAndResourceEntityBreadcrumb;
  }

  return null;
};
