import React from 'react';
import { ProductVersionEntityOverview } from './overview/ProductVersionEntityOverview';
import { ProductDeployments } from '../deployments/ProductDeployments';
import { EntityLayout } from '../../../../entity-layout/EntityLayout';

export const ProductVersionEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <ProductVersionEntityOverview />
    </EntityLayout.Route>
    <EntityLayout.Route path="/deployments" title="Deployments">
      <ProductDeployments />
    </EntityLayout.Route>
  </EntityLayout>
);
