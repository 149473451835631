import { Entity } from '@backstage/catalog-model';

export const checkEntityUpdate = (
  delay: number,
  onUpdate: () => Promise<Entity | undefined>,
  onSuccess: () => void,
  onTimeout: () => void,
) => {
  // 2000 > 3000 > 4000, already waited 9 seconds
  if (delay > 4000) {
    onTimeout();
    return Promise.resolve();
  }

  // After delay, fetch entity and check if lifecycle has been updated
  // If not, try again
  return new Promise<void>(resolve => {
    setTimeout(async () => {
      const updatedEntity = await onUpdate();

      if (
        (updatedEntity?.spec?.lifecycle as string)?.toLowerCase() ===
        'initialized'
      ) {
        onSuccess();
        resolve();
      }

      resolve(checkEntityUpdate(delay + 1000, onUpdate, onSuccess, onTimeout));
    }, delay);
  });
};
