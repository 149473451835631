import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { DbApiClient, dbApiRef } from './api/api';

export const dbapiPlugin = createPlugin({
  id: 'dbapi',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: dbApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new DbApiClient(discoveryApi, fetchApi),
    }),
  ],
});

export const DbApiPage = dbapiPlugin.provide(
  createRoutableExtension({
    name: 'DbApiPage',
    component: () =>
      import('./components/DbApiDetails').then(m => m.DbApiDetails),
    mountPoint: rootRouteRef,
  }),
);
