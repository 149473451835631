import { useDeploymentsStore } from '../../../../store/deployments-store';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import React, { useEffect } from 'react';
import {
  GenericSecretsContentHeader,
  SecretsContentHeaderProps,
} from '../GenericSecretsContentHeader';

export type ResourceSecretsContentHeaderProps = SecretsContentHeaderProps & {
  onLoadingChange: (loading: boolean) => void;
};

export const ResourceSecretsContentHeader = ({
  onChange,
  defaultResourceName,
  onLoadingChange,
  ...restProps
}: ResourceSecretsContentHeaderProps) => {
  const productVersion = useDeploymentsStore(state => state.productVersion!);

  const { entities: components, loading } = useRelatedEntities(productVersion, {
    kind: 'Component',
    type: RELATION_DEPENDS_ON,
  });

  useEffect(() => {
    onLoadingChange(loading);
  }, [loading, onLoadingChange]);

  return (
    <GenericSecretsContentHeader
      onChange={onChange}
      defaultResourceName={defaultResourceName}
      selectorEntities={components}
      noEntitiesMessage="No components were found in this product"
      isLoading={loading}
      {...restProps}
    />
  );
};
