import React, { useMemo } from 'react';
import { type Entity } from '@backstage/catalog-model';
import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { Card, makeStyles } from '@material-ui/core';

export type ComponentAndResourceCardProps = {
  entity: Entity;
};

const useComponentAndResourceCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  errorContainer: {
    margin: theme.spacing(2),
  },
}));

export const ComponentAndResourceCard = (
  props: ComponentAndResourceCardProps,
) => {
  const { entity } = props;

  const classes = useComponentAndResourceCardStyles();

  const type = useMemo(() => entity.spec?.type?.toString() ?? '', [entity]);

  return (
    <Card>
      <EntityPresentation size="small">
        <div className={classes.content}>
          <EntityPresentationHeader
            title={entity.metadata.title ?? 'No title'}
            type={type}
          />
          <EntityPresentationDescription
            description={entity.metadata.description ?? 'No description'}
          />
        </div>
      </EntityPresentation>
    </Card>
  );
};

ComponentAndResourceCard.displayName = 'ComponentAndResourceCard';
