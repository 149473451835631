import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Inventory2SearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" titleAccess="Inventory2Icon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V8.725C2.7 8.54167 2.45833 8.30417 2.275 8.0125C2.09167 7.72083 2 7.38333 2 7V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V7C22 7.38333 21.9083 7.72083 21.725 8.0125C21.5417 8.30417 21.3 8.54167 21 8.725V11.8096C20.4245 11.2573 19.7476 10.8099 19 10.4982V9H5V20H11.0218C11.5374 20.8053 12.2256 21.4894 13.0343 22H5ZM4 7H20V4H4V7Z"
      />
      <path d="M9 12H11.8096C11.2573 12.5755 10.8099 13.2524 10.4982 14H9V12Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9 20.3L22.1 23.5L23.5 22.1L20.3 18.9C20.5 18.55 20.6667 18.175 20.8 17.775C20.9333 17.375 21 16.95 21 16.5C21 15.25 20.5625 14.1875 19.6875 13.3125C18.8125 12.4375 17.75 12 16.5 12C15.25 12 14.1875 12.4375 13.3125 13.3125C12.4375 14.1875 12 15.25 12 16.5C12 17.75 12.4375 18.8125 13.3125 19.6875C14.1875 20.5625 15.25 21 16.5 21C16.95 21 17.375 20.9333 17.775 20.8C18.175 20.6667 18.55 20.5 18.9 20.3ZM16.5 19C17.2 19 17.7917 18.7583 18.275 18.275C18.7583 17.7917 19 17.2 19 16.5C19 15.8 18.7583 15.2083 18.275 14.725C17.7917 14.2417 17.2 14 16.5 14C15.8 14 15.2083 14.2417 14.725 14.725C14.2417 15.2083 14 15.8 14 16.5C14 17.2 14.2417 17.7917 14.725 18.275C15.2083 18.7583 15.8 19 16.5 19Z"
      />
    </svg>
  </SvgIcon>
);
