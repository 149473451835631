export class FailedToFetchError extends Error {
  constructor(status: number, errorMessage: string) {
    const message = `[DBAPI] Failed to fetch: ${status} ${errorMessage}`;

    super(message);

    this.name = this.constructor.name;
    this.message = message;
  }
}
