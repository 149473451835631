import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useHeaderStyles = makeStyles(theme => ({
  root: {
    gridArea: 'pageHeader',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 100,
    transition: 'box-shadow 0.3s ease-in-out',
    height: '64px',
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type HeaderProps = {
  children: ReactNode;
  withBottomBorder?: boolean;
};

const Header = (props: HeaderProps) => {
  const { children, withBottomBorder = false } = props;
  const classes = useHeaderStyles();
  const className = withBottomBorder
    ? `${classes.root} ${classes.bottomBorder}`
    : classes.root;
  return <div className={className}>{children}</div>;
};

Header.displayName = 'Header';

export { Header };
