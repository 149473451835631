import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  EntityGithubInsightsComplianceCard,
  EntityGithubInsightsContributorsCard,
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsReleasesCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import { Button, Grid } from '@material-ui/core';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage-community/plugin-github-actions';
import { EmptyState } from '@backstage/core-components';
import React from 'react';

export const CicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={e => Boolean(isGithubInsightsAvailable(e))}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityGithubInsightsLanguagesCard />
          <EntityGithubInsightsContributorsCard />
          <EntityGithubInsightsComplianceCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityGithubInsightsReleasesCard />
        </Grid>
      </Grid>
    </EntitySwitch.Case>
    <EntitySwitch.Case if={e => Boolean(isGithubActionsAvailable(e))}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="
        You need to add an annotation (github.com/project-slug) to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
