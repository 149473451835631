import { Entity } from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';

export const getEntityDisplayName = (entity: Entity) => {
  switch (entity.kind) {
    case 'Component':
    case 'API':
    case 'Resource':
      return entity.metadata.title ?? entity.metadata.name;
    case 'Group':
    case 'User':
      return (
        ((entity.spec?.profile as JsonObject)?.displayName as string) ??
        entity.metadata.name
      );
    default:
      return entity.metadata.name;
  }
};

export const getEntityOverline = (entity: Entity) => {
  switch (entity.kind) {
    case 'Component':
    case 'API':
    case 'Resource':
      return `${entity.spec?.type as string}`;
    case 'Group':
      return `${entity.kind} - ${entity.spec?.type as string}`;
    case 'User':
      return `${entity.kind} - ${entity.metadata.name}`;
    default:
      return `${entity.kind} - ${entity.spec?.type as string}`;
  }
};
