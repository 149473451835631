import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const EntityPresentationMetricsSkeleton = () => (
  <Box display="flex" data-testid="metrics-skeleton">
    <Box>
      <Skeleton width={40} />
      <Skeleton width={88} />
    </Box>
    <Divider orientation="vertical" style={{ margin: '0 1rem' }} />
    <Box>
      <Skeleton width={60} />
      <Skeleton width={25} />
    </Box>
  </Box>
);
