import React from 'react';
import { type Entity } from '@backstage/catalog-model';
import { Divider, Grid, IconButton, makeStyles, Card } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEntityRoute } from '../../hooks/useEntityRoute';
import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { useMetrics } from '../../hooks/metrics/useMetrics';
import { useNavigate } from 'react-router';
import { ArrowRightAltIcon } from 'backstage-plugin-icons-react';

const usePackageDiscoveryCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  errorContainer: {
    margin: theme.spacing(2),
  },
  description: {
    flexBasis: '80px',
  },
}));

export const PackageDiscoveryCard = ({
  packageEntity,
}: {
  packageEntity: Entity;
}) => {
  const classes = usePackageDiscoveryCardStyles();
  const packageRoute = useEntityRoute(packageEntity);
  const navigate = useNavigate();

  const { loading, metrics, error } = useMetrics(packageEntity, [
    'owner',
    'namespace',
    'products',
  ]);

  const handleClick = () => {
    if (packageRoute) {
      navigate(packageRoute);
    }
  };

  const actionButton = packageRoute ? (
    <IconButton onClick={handleClick} data-testid="action-button">
      <ArrowRightAltIcon />
    </IconButton>
  ) : null;

  return (
    <Grid item key={packageEntity.metadata.uid} xs={12} lg={6}>
      <Card>
        <EntityPresentation size="small">
          <div className={classes.content}>
            <EntityPresentationHeader
              title={packageEntity.metadata.title ?? 'No title'}
              type={(packageEntity.spec?.type as string) ?? ''}
              actionButton={actionButton}
              targetLink={packageRoute}
            />
            <EntityPresentationDescription
              description={
                packageEntity.metadata.description ?? 'No description'
              }
              className={classes.description}
            />
          </div>
          <Divider style={{ marginTop: 'auto' }} />
          {error ? (
            <Alert severity="error" className={classes.errorContainer}>
              {error.message}
            </Alert>
          ) : (
            <EntityPresentationMetrics metrics={metrics} loading={loading} />
          )}
        </EntityPresentation>
      </Card>
    </Grid>
  );
};
