import React from 'react';
import { useLocation } from 'react-router';
import {
  Link,
  sidebarConfig,
  useSidebarOpenState,
} from '@backstage/core-components';
import { IconComponent } from '@backstage/core-plugin-api';
import {
  Box,
  Button,
  Typography,
  lighten,
  makeStyles,
} from '@material-ui/core';
import { classNames } from '../../../utils/classNames';

const useCustomSidebarItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.navigation.color,
    background: theme.palette.navigation.background,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: theme.spacing(7),
    cursor: 'pointer',
    '&:hover [class*="closeIconContainer"]': {
      display: 'flex',
    },
    transition: 'all 0.2s ease-in-out',
  },
  buttonItem: {
    background: 'none',
    border: 'none',
    width: '100%',
    margin: 0,
    padding: 0,
    textAlign: 'inherit',
    font: 'inherit',
    textTransform: 'none',
  },
  closed: {
    width: sidebarConfig.drawerWidthClosed,
  },
  closedItemIcon: {
    width: '100%',
    justifyContent: 'center',
  },
  open: {
    [theme.breakpoints.up('sm')]: {
      width: sidebarConfig.drawerWidthOpen,
    },
  },
  iconContainer: {
    boxSizing: 'border-box',
    height: '100%',
    width: sidebarConfig.iconContainerWidth,
    marginRight: -theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '0',
    flexShrink: 0,
    color: theme.palette.navigation.color,
    fill: theme.palette.navigation.color,
  },
  label: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    lineHeight: 'auto',
    flex: '3 1 auto',
    width: '110px',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
  },
  highlightable: {
    '&:hover': {
      background: '#FFFFFF15',
    },
  },
  selected: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: sidebarConfig.selectedIndicatorWidth,
      background: theme.palette.navigation.indicator,
    },
    '& $iconContainer': {
      color: theme.palette.navigation.selectedColor,
    },
    '&$root': {
      color: theme.palette.navigation.selectedColor,
      background: theme.palette.primary.main,
    },
    '&$root:hover': {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));

type CustomSidebarItemProps = {
  text: string | React.ReactNode;
  icon: IconComponent;
  to: string;
  exactMatch?: boolean;
};

export const CustomSidebarItem = ({
  icon: Icon,
  text,
  to,
  exactMatch,
}: CustomSidebarItemProps) => {
  const { pathname } = useLocation();
  const { isOpen } = useSidebarOpenState();
  const classes = useCustomSidebarItemStyles();

  const isActive = exactMatch
    ? pathname.toLowerCase() === to.toLowerCase() ||
      pathname.toLowerCase() === `${to.toLowerCase()}/`
    : `${pathname.toLowerCase()}/`.startsWith(`${to.toLowerCase()}/`); // add trailing slash to match last word correctly

  const labelElement =
    typeof text === 'string' ? (
      <Typography
        variant="subtitle2"
        component="span"
        className={classes.label}
      >
        {text}
      </Typography>
    ) : (
      text
    );

  return (
    <Link
      to={to}
      underline="none"
      component={Button}
      role="button"
      className={classNames(
        classes.root,
        isOpen ? classes.open : classes.closed,
        classes.buttonItem,
        classes.highlightable,
        isActive ? classes.selected : '',
      )}
    >
      <>
        <Box component="span" className={classes.iconContainer}>
          <Icon fontSize="small" />
        </Box>
        {isOpen && labelElement}
      </>
    </Link>
  );
};
