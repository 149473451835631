import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { StatusBadge } from '../../StatusBadge';
import { Alert } from '@material-ui/lab';

const useDeploymentAccordionStatusStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '150px',
    flexDirection: 'column',
  },
  badge: {
    marginTop: theme.spacing(0.5),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 'initial',
  },
  statusContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  noStatusAlert: {
    width: '100%',
    '& [class*="MuiAlert-icon"], & [class*="MuiAlert-message"]': {
      padding: 0,
    },
  },
}));

interface DeploymentAccordionStatusProps {
  status?: {
    sync: string;
    health: string;
  };
}

export const DeploymentAccordionStatus = ({
  status,
}: DeploymentAccordionStatusProps) => {
  const classes = useDeploymentAccordionStatusStyles();

  return (
    <div className={classes.root}>
      {status ? (
        <>
          <div className={classes.statusContainer}>
            <StatusBadge className={classes.badge} status={status?.health} />
            <Typography variant="body2">
              {status?.health ?? 'Unknown'}
            </Typography>
          </div>
          <div className={classes.statusContainer}>
            <StatusBadge className={classes.badge} status={status?.sync} />
            <Typography variant="body2">{status?.sync ?? 'Unknown'}</Typography>
          </div>
        </>
      ) : (
        <Alert severity="warning" className={classes.noStatusAlert}>
          No status
        </Alert>
      )}
    </div>
  );
};
