import { makeStyles, MenuItem, Select, SelectProps } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { getEntityIcon } from '@internal/backstage-plugin-adeo-core-components-react';
import React, { useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';

const useEntitySelectorStyles = makeStyles(theme => ({
  selectValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  formControl: {
    minWidth: '300px',
  },
  select: {
    minHeight: theme.typography.pxToRem(40),
  },
}));

interface EntitySelectorProps extends Omit<SelectProps, 'onChange' | 'value'> {
  entities: Entity[];
  value?: Entity;
  onChange: (entity: Entity) => void;
}

export const EntitySelector = ({
  entities,
  onChange,
  value,
  ...selectProps
}: EntitySelectorProps) => {
  const classes = useEntitySelectorStyles();

  const renderSelectValue = useCallback(
    (rawValue: unknown) => {
      if (!rawValue) return '';

      const parsedValue: Entity = JSON.parse(rawValue as string); // stringified Entity
      const Icon = getEntityIcon(parsedValue);

      return (
        <span className={classes.selectValue}>
          <Icon fontSize="small" />
          {parsedValue.metadata.title ?? parsedValue.metadata.name}
        </span>
      );
    },
    [classes.selectValue],
  );

  const onEntityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    try {
      onChange(JSON.parse(event.target.value as string));
    } catch (e) {
      throw new Error(
        `Failed to parse stringified entity ${event.target.value}`,
      );
    }
  };

  const selectValue = value ? JSON.stringify(value) : undefined;

  return (
    <FormControl
      variant="outlined"
      size="small"
      className={classes.formControl}
    >
      <Select
        {...selectProps}
        onChange={onEntityChange}
        value={selectValue}
        renderValue={renderSelectValue}
        MenuProps={{
          ...selectProps.MenuProps,
          getContentAnchorEl: null,
        }}
        className={classes.select}
      >
        {entities.map(entity => {
          const Icon = getEntityIcon(entity);
          return (
            <MenuItem
              value={JSON.stringify(entity)}
              className={classes.selectValue}
              key={entity.metadata.uid}
            >
              <Icon />
              {entity.metadata.title ?? entity.metadata.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
