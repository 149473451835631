import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const KeyboardArrowDownIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" titleAccess="KeyboardArrowDownIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
    >
      <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
    </svg>
  </SvgIcon>
);
