import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Divider, Box } from '@material-ui/core';

const useEntityPresentationMembersSkeletonStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  mainMembers: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  contacts: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  otherMembersDisplay: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: -theme.spacing(2),
    },
  },
  otherMembers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  avatarGroupSkeleton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export const EntityPresentationMembersSkeleton = () => {
  const classes = useEntityPresentationMembersSkeletonStyles();

  const AvatarGroupSkeleton = (
    <div className={classes.avatarGroupSkeleton}>
      <Skeleton variant="circle" width={40} height={40} />
      <Box>
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={140} />
      </Box>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.mainMembers}>
        <Skeleton variant="text" width={100} />
        <div className={classes.contacts}>
          {AvatarGroupSkeleton}
          <Divider orientation="vertical" flexItem />
          {AvatarGroupSkeleton}
        </div>
      </div>
      <div className={classes.otherMembers}>
        <Skeleton variant="text" width={100} />
        <div className={classes.otherMembersDisplay}>
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton variant="circle" width={40} height={40} />
        </div>
      </div>
    </div>
  );
};
