import { useState } from 'react';

export const VIEW_MODE_KEY = 'aap.viewMode';

export type ViewMode = 'grid' | 'list';

type UseViewMode = () => [ViewMode, (mode: ViewMode) => void];

export const useViewMode: UseViewMode = () => {
  const [viewMode, setViewMode] = useState<ViewMode>(
    (localStorage.getItem(VIEW_MODE_KEY) as ViewMode) || 'grid',
  );

  return [
    viewMode,
    (mode: ViewMode) => {
      localStorage.setItem(VIEW_MODE_KEY, mode);
      setViewMode(mode);
    },
  ];
};
