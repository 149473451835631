import { useDeploymentsStore } from '../store/deployments-store';
import React from 'react';
import { ApiDeploymentContent } from './api/ApiDeploymentContent';
import { MongoDbDeploymentContent } from './mongodb/MongoDbDeploymentContent';
import { CronjobDeploymentContent } from './cronjob/CronjobDeploymentContent';
import { PostgresqlDeploymentContent } from './postgresql/PostgresqlDeploymentContent';
import { FrontendDeploymentContent } from './frontend/FrontendDeploymentContent';

export const DeploymentDrawerContent = () => {
  const entity = useDeploymentsStore(state => state.entity);

  switch (entity?.spec?.type) {
    case 'api':
      return <ApiDeploymentContent />;
    case 'frontend':
      return <FrontendDeploymentContent />;
    case 'mongodb':
      return <MongoDbDeploymentContent />;
    case 'cronjob':
      return <CronjobDeploymentContent />;
    case 'postgresql':
      return <PostgresqlDeploymentContent />;
    default:
      return null;
  }
};
