import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import React from 'react';
import { DefaultEntityPage } from '../DefaultEntityPage';
import { ServiceEntityPage } from './ServiceEntityPage';
import { FrontendEntityPage } from './FrontendEntityPage';
import { ApiEntityPage } from './ApiEntityPage';
import { PackageEntityPage } from './package/PackageEntityPage';
import { SubscriptionEntityPage } from './subscription/SubscriptionEntityPage';
import { ProductRefEntityPage } from './product/ref/ProductRefEntityPage';
import { ProductVersionEntityPage } from './product/version/ProductVersionEntityPage';

export const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServiceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('frontend')}>
      {FrontendEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('product')}>
      {ProductRefEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('productVersion')}>
      {ProductVersionEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('api')}>
      {ApiEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('package')}>
      {PackageEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('subscription')}>
      <SubscriptionEntityPage />
    </EntitySwitch.Case>

    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
