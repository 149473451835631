import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitBranchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" titleAccess="GitBranchIcon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 2V14.126C8.39006 14.4838 9.48769 15.57 9.86171 16.9535C11.6666 16.758 13.3611 15.9526 14.6569 14.6569C15.9526 13.3611 16.758 11.6666 16.9535 9.86171C15.2519 9.4017 14 7.84705 14 6C14 3.79086 15.7909 2 18 2C20.2091 2 22 3.79086 22 6C22 7.8779 20.7059 9.45354 18.9609 9.88384C18.7544 12.212 17.737 14.4052 16.0711 16.0711C14.4052 17.737 12.212 18.7544 9.88383 18.9609C9.45354 20.7059 7.8779 22 6 22C3.79086 22 2 20.2091 2 18C2 16.1362 3.27477 14.5701 5 14.126V2H7ZM18 4C16.8954 4 16 4.89543 16 6C16 7.10457 16.8954 8 18 8C19.1046 8 20 7.10457 20 6C20 4.89543 19.1046 4 18 4ZM6 16C4.89543 16 4 16.8954 4 18C4 19.1046 4.89543 20 6 20C7.10457 20 8 19.1046 8 18C8 16.8954 7.10457 16 6 16Z" />
    </svg>
  </SvgIcon>
);
