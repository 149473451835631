import { TabsSection } from '../TabsSection';
import React from 'react';
import { Section } from '../Section';
import { SectionContent } from '../SectionContent';
import { Alert } from '@material-ui/lab';
import { useComponentLinks } from './useComponentLinks';
import { useDeploymentsStore } from '../../../store/deployments-store';

const getTabsTitle = (entityType: 'api' | 'frontend') => {
  const commonTabs = [
    { label: 'Local', value: 'local' },
    { label: 'Group', value: 'group' },
  ];

  return entityType === 'api'
    ? [
        ...commonTabs,
        {
          label: 'Global',
          value: 'global',
        },
      ]
    : commonTabs;
};

export const ComponentLinks = () => {
  const entity = useDeploymentsStore(state => state.entity);
  const useComponentLinksResult = useComponentLinks();

  if ('error' in useComponentLinksResult) {
    return (
      <Section title="Links">
        <Alert severity="error">{useComponentLinksResult.error.message}</Alert>
      </Section>
    );
  }

  return (
    <Section title="Links">
      <TabsSection
        tabsTitle={getTabsTitle(entity!.spec!.type as 'api' | 'frontend')}
        renderContent={currentTab => (
          <SectionContent
            currentTab={currentTab}
            tabsContent={useComponentLinksResult.data}
            showLineNumber={false}
            emptyDataMessage="No links to display for this component"
            renderItemValue={value => (
              <a
                href={value}
                rel="noreferrer noopener"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                {value}
              </a>
            )}
          />
        )}
      />
    </Section>
  );
};
