import { MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getNamespaceMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (!entity.metadata.namespace)
    return {
      metrics: [
        {
          label: 'Namespace',
          error: new Error(
            `No namespace found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };
  const metric = createMetrics(entity.metadata.namespace, 'Namespace');

  return { metrics: [metric] };
};
