import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { Entity, RELATION_DEPENDS_ON } from '@backstage/catalog-model';

export const useProductVersions = (entity: Entity) => {
  if (
    entity.kind.toLowerCase() !== 'component' ||
    (entity.spec?.type as string)?.toLowerCase() !== 'product'
  )
    throw new Error(
      `Cannot get product versions on an entity that is not a product ref (kind: ${entity.kind}, type: ${entity.spec?.type})`,
    );

  const { entities, loading, error } = useRelatedEntities(entity, {
    kind: 'component',
    type: RELATION_DEPENDS_ON,
  });

  return { entities, loading, error };
};
