import React, { forwardRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip, { type ChipProps } from '@material-ui/core/Chip';
import { EntityPresentationContext } from '../EntityPresentationContext';
import { IconComponent } from '@backstage/core-plugin-api';
import { ChipLink } from '../../ChipLink';
import { Link } from '@backstage/core-components';

/**
 * A tag to be displayed in the header of an entity presentation.
 *
 * @public
 */
export type Tag = {
  icon?: IconComponent;
  label: string | React.ReactNode;
  variant?: ChipProps['variant'];
  color?: ChipProps['color'];
  link?: string | null;
};

/**
 * Props for {@link EntityPresentationHeader}.
 *
 * @public
 */
export type EntityPresentationHeaderProps = {
  title: string;
  type: string;
  subtitle?: string;
  tags?: Tag[];
  actionButton?: React.ReactNode;
  targetLink?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const useEntityPresentationHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    margin: theme.spacing(2),
  },
  head: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  headTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: theme.spacing(0),
    flex: 1,
  },
  headLink: {
    color: 'inherit',
    width: '100%',
  },
  headline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    width: '100%',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'start',
  },
  ellipsis: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

type TagChipProps = {
  tag: Tag;
  size: 'small' | 'medium';
};

const TagChip = (props: TagChipProps) => {
  const { tag, size } = props;
  const iconComponent = tag.icon ? <tag.icon /> : undefined;
  const ChipComponent = tag.link ? ChipLink : Chip;

  return (
    <ChipComponent
      key={tag.label?.toString()}
      icon={iconComponent}
      label={tag.label}
      variant={tag.variant}
      color={tag.color}
      to={tag.link ?? ''}
      size={size}
    />
  );
};

/**
 * Displays a header for an entity presentation.
 *
 * @remarks
 *
 * This component displays a header for an entity presentation, including a title, type, subtitle,
 * and tags. This component is intended to be used within an {@link EntityPresentation}.
 *
 * @public
 */
export const EntityPresentationHeader = forwardRef<
  HTMLDivElement,
  EntityPresentationHeaderProps
>((props, ref) => {
  const { size } = useContext(EntityPresentationContext);
  const classes = useEntityPresentationHeaderStyles();
  const {
    title,
    type,
    subtitle,
    tags,
    className,
    actionButton,
    targetLink,
    ...restProps
  } = props;

  const titleAndSubtitle = (
    <>
      <Typography
        className={classes.ellipsis}
        variant={size === 'small' ? 'h5' : 'h3'}
      >
        {title}
      </Typography>
      <Typography
        className={classes.ellipsis}
        variant={size === 'small' ? 'body2' : 'body1'}
      >
        {subtitle}
      </Typography>
    </>
  );

  return (
    <div
      ref={ref}
      className={`${className ?? ''} ${classes.root}`}
      {...restProps}
    >
      <div className={classes.head}>
        <div className={classes.headTitle}>
          <Typography className={classes.ellipsis} variant="overline">
            {type}
          </Typography>
          {targetLink ? (
            <Link to={targetLink} color="inherit" className={classes.headline}>
              {titleAndSubtitle}
            </Link>
          ) : (
            <div className={classes.headline}>{titleAndSubtitle}</div>
          )}
        </div>

        <div className={classes.tags}>
          {tags?.map(tag => (
            <TagChip key={tag.label?.toString()} tag={tag} size={size} />
          ))}
        </div>
      </div>

      {actionButton && (
        <div className={classes.actionButton}>{actionButton}</div>
      )}
    </div>
  );
});

EntityPresentationHeader.displayName = 'EntityPresentationHeader';
