import { EntityAboutCard } from '@backstage/plugin-catalog';
import { RelationsContent } from '../../content/RelationsContent';
import React from 'react';
import { Grid } from '@material-ui/core';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { CicdContent } from '../../content/CicdContent';
import { EntityWarningContent } from '../../content/EntityWarningContent';
import { TechDocsContent } from '../../content/TechDocsContent';
import { PullRequestsContent } from '../../content/PullRequestContent';
import { LinksCard } from '../../../links/LinksCard';
import { EntityLayout } from '../../entity-layout/EntityLayout';

const FrontendEntityOverview = (
  <Grid container spacing={3} alignItems="stretch">
    {EntityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard
        variant="gridItem"
        height={400}
        kinds={['API', 'Component']}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <LinksCard cols={1} />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
  </Grid>
);

export const FrontendEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {FrontendEntityOverview}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull requests">
      {PullRequestsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/github-insights" title="Github insights">
      {CicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/relations" title="Relations">
      {RelationsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
