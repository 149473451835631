import React, { useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import {
  InputLabel,
  FormHelperText,
  MenuItem,
  Select,
  LinearProgress,
  ListSubheader,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useUserInfo } from '../../../utils/useUserInfo';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { Product } from './types';

export const UserProducts = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  const configApi = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);
  const [possibleProducts, setPossibleProducts] = useState<Product[]>([]);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorMessage] = useState('');
  const userInfo = useUserInfo();

  const state = useAsync(async () => {
    const user = await userInfo;
    const backendUrl = configApi.getString('backend.baseUrl');
    const response = await fetch(
      `${backendUrl}/api/user/${user.ldap}/products_available`,
    );
    if (response.ok) {
      const userAvailableProducts = await response.json();
      setPossibleProducts(userAvailableProducts.products);
    } else {
      setIsError(true);
      setErrorMessage('Error when retrieving user products');
      throw new Error(
        `Failed to fetch user available products: ${response.status}: ${response.statusText}`,
      );
    }
  });

  const renderMenuItems = useCallback(() => {
    const items: React.ReactNode[] = [];
    let hasShownEnrolledHeader = false;

    possibleProducts.forEach((product, index) => {
      if (index === 0 && !product.isIntegrated) {
        items.push(
          <ListSubheader key="products-header">Products:</ListSubheader>,
        );
      }
      if (!hasShownEnrolledHeader && product.isIntegrated) {
        items.push(
          <ListSubheader key="enrolled-products-header">
            Enrolled products:
          </ListSubheader>,
        );
        hasShownEnrolledHeader = true;
      }

      items.push(
        <MenuItem
          key={product.id}
          value={product.id}
          disabled={product.isIntegrated}
        >
          {product.name}
        </MenuItem>,
      );
    });

    return items;
  }, [possibleProducts]);

  return (
    <>
      {possibleProducts && possibleProducts.length > 0 && (
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0 && !formData}
        >
          <InputLabel htmlFor="product">Product</InputLabel>
          <Select
            labelId="product"
            id="product"
            onChange={e => onChange(e.target?.value as string)}
            defaultValue=""
          >
            {renderMenuItems()}
          </Select>
          <FormHelperText id="entityName">
            This list is populated with your products.
          </FormHelperText>
        </FormControl>
      )}
      {!isError &&
        !state.loading &&
        possibleProducts &&
        possibleProducts.length === 0 && (
          <Alert severity="warning">
            You must be a member of a Tangram product.
          </Alert>
        )}
      {!isError && state.loading && <LinearProgress />}
      {isError && <Alert severity="error">{errorText}</Alert>}
    </>
  );
};
