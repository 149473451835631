import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { datadogRum } from '@datadog/browser-rum';

export const InitRUM = () => {
  const config = useApi(configApiRef);
  const rumAppId =
    config.getOptionalString('app.datadogRum.applicationId') ?? 'noApp';
  const rumTokenId =
    config.getOptionalString('app.datadogRum.clientToken') ?? 'noToken';
  const rumEnv = config.getOptionalString('app.datadogRum.env');
  datadogRum.init({
    applicationId: rumAppId,
    clientToken: rumTokenId,
    site: 'datadoghq.eu',
    service: 'aap-core-frontend',
    env: rumEnv,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
  return null;
};
