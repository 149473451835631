import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GithubIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" titleAccess="GithubIcon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 2C6.47478 2 2 6.47478 2 12C2 16.4252 4.86261 20.1626 8.83739 21.487C9.33739 21.5748 9.52435 21.2748 9.52435 21.0122C9.52435 20.7748 9.51217 19.9878 9.51217 19.1496C7 19.6122 6.35043 18.5374 6.15043 17.9757C6.03739 17.6878 5.55043 16.8 5.12435 16.5626C4.77478 16.3748 4.27478 15.9122 5.11217 15.9C5.9 15.8878 6.46261 16.6252 6.65043 16.9252C7.55043 18.4383 8.98783 18.0122 9.56261 17.7496C9.64956 17.1 9.91217 16.6626 10.2 16.4122C7.97478 16.1626 5.65043 15.3 5.65043 11.4748C5.65043 10.3878 6.03739 9.48783 6.67478 8.78783C6.57478 8.53739 6.22522 7.51217 6.77478 6.13739C6.77478 6.13739 7.61217 5.87478 9.52522 7.16261C10.3252 6.93739 11.1748 6.82522 12.0252 6.82522C12.8748 6.82522 13.7252 6.93826 14.5252 7.16261C16.4383 5.86261 17.2748 6.13739 17.2748 6.13739C17.8252 7.51217 17.4748 8.53739 17.3748 8.78783C18.0122 9.48783 18.4 10.3748 18.4 11.4748C18.4 15.3122 16.0626 16.1617 13.8374 16.4122C14.2 16.7252 14.5122 17.3252 14.5122 18.2626C14.5122 19.6 14.5 20.6748 14.5 21.0122C14.5 21.2748 14.6878 21.5878 15.187 21.4878C19.1383 20.1626 22 16.4122 22 12C22 6.47478 17.5252 2 12 2Z" />
    </svg>
  </SvgIcon>
);
