import React from 'react';
import { Section } from '../Section';
import { SectionContent } from '../SectionContent';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useComponentConfig } from './useComponentConfig';

const useComponentConfigStyles = makeStyles(theme => ({
  content: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
}));

export const ComponentConfig = () => {
  const classes = useComponentConfigStyles();
  const configOrError = useComponentConfig();

  if ('error' in configOrError) {
    return (
      <Section title="Config">
        <Alert severity="error">{configOrError.error.message}</Alert>
      </Section>
    );
  }

  return (
    <Section title="Config">
      <SectionContent
        tabsContent={configOrError.data}
        showLineNumber
        currentTab="config"
        className={classes.content}
        emptyDataMessage="No config to display for this component"
      />
    </Section>
  );
};
