import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EmptyState } from '@backstage/core-components';
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';

export const PullRequestsContent = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
        <EntityGithubPullRequestsContent />
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <EmptyState
          title="No Github pull requests available for this entity"
          missing="info"
          description="
          You need to add an annotation (github.com/project-slug) to your component if you want to see Github pull requests. You can read more about annotations in Backstage by clicking the button below."
          action={
            <Button
              variant="contained"
              color="primary"
              href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
            >
              Read more
            </Button>
          }
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
