import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { DatabaseIcon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getResourcesMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (entity.spec?.type !== 'product' && entity.spec?.type !== 'productVersion')
    return {
      metrics: [
        {
          label: 'Resources',
          error: new Error(
            'You cannot display resources for a non-product or a non-product-version entity',
          ),
        },
      ],
    };
  const resourcesReferences = getEntityRelations(entity, RELATION_DEPENDS_ON, {
    kind: 'resource',
  });
  if (resourcesReferences.length === 0)
    return {
      metrics: [
        {
          label: 'Resources',
          error: new Error(
            `No resources found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };
  const metric = createMetrics(
    resourcesReferences.length,
    'Resources',
    DatabaseIcon,
  );
  return { metrics: [metric] };
};
