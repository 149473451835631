import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const GitCommitIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" titleAccess="GitCommitIcon">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM7.10002 11C7.56329 8.71776 9.58104 7 12 7C14.419 7 16.4367 8.71776 16.9 11H23.96V13H16.9C16.4367 15.2822 14.419 17 12 17C9.58104 17 7.56329 15.2822 7.10002 13H0.0499992V11H7.10002Z" />
    </svg>
  </SvgIcon>
);
