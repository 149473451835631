import React from 'react';
import { Table } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useGetUserProducts } from './useGetUserProducts';
import { Alert } from '@material-ui/lab';
import { Link } from '@material-ui/core';

export const UserProductsCard = () => {
  const { entity } = useEntity();
  const state = useGetUserProducts(entity.metadata.name);
  const isLoading = !state.error && state.loading;

  return state.error ? (
    <Alert severity="error">{state.error.message}</Alert>
  ) : (
    <Table
      isLoading={isLoading}
      columns={[
        { field: 'name', title: 'Product name' },
        { field: 'status', title: 'Tangram status' },
      ]}
      options={{
        paging: false,
        filtering: false,
        search: false,
      }}
      title="Your products"
      data={
        state.value?.map(product => ({
          ...product,
          name: <Link href={product.link}>{product.name}</Link>,
        })) ?? []
      }
      emptyContent={
        <Alert severity="warning">
          You must be a member of a Tangram product.
        </Alert>
      }
    />
  );
};
