import { TabsSection } from '../../TabsSection';
import React from 'react';
import { Section } from '../../Section';
import { Alert } from '@material-ui/lab';
import { useResourceSecrets } from './useResourceSecrets';
import { ResourceSecretsContentHeader } from './ResourceSecretsContentHeader';
import { SectionContentWithHeader } from '../SectionContentWithHeader';

export const ResourceSecrets = () => {
  const secretsOrError = useResourceSecrets();

  if ('error' in secretsOrError) {
    return (
      <Section title="Secrets">
        <Alert severity="error">{secretsOrError.error.message}</Alert>
      </Section>
    );
  }

  return (
    <Section title="Secrets">
      <TabsSection
        tabsTitle={[{ label: 'Components', value: 'components' }]}
        renderContent={() => (
          <SectionContentWithHeader
            secretData={secretsOrError.data.components.data}
            emptySecretsMessage="No secrets to display for this component in this resource"
            SecretsContentHeaderComponent={ResourceSecretsContentHeader}
          />
        )}
      />
    </Section>
  );
};
