import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useAsyncEntity } from '@backstage/plugin-catalog-react';
import {
  Button,
  CircularProgress,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import { TANGRAM_ID_ANNOTATION } from 'custom-annotations';
import React, { useState } from 'react';
import { LineStartCircleIcon } from 'backstage-plugin-icons-react';
import { Alert } from '@material-ui/lab';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { checkEntityUpdate } from './checkEntityUpdate';

const SNACKBAR_DURATION = 2000;

const useInitializeManifestButtonStyles = makeStyles(theme => ({
  snackbar: {
    maxWidth: 600,
  },
  circularProgress: {
    color: theme.palette.text.disabled,
  },
}));

export const InitializeManifestButton = () => {
  const classes = useInitializeManifestButtonStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<Error>();

  const config = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { entity, refresh } = useAsyncEntity();

  const onClick = async () => {
    setIsLoading(true);

    const tangramId = entity?.metadata.annotations?.[TANGRAM_ID_ANNOTATION];
    const name = entity?.metadata.name;
    const namespace = entity?.metadata.namespace;

    const response = await fetch(
      `${config.getString('backend.baseUrl')}/api/catalog/products/${tangramId}/init`,
      {
        method: 'POST',
        body: JSON.stringify({
          name,
          namespace,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      const body = await response.json();
      setIsLoading(false);
      setError(body.error);
      setOpenSnackbar(true);
      return;
    }

    try {
      await checkEntityUpdate(
        2000,
        () => catalogApi.getEntityByRef(stringifyEntityRef(entity!)),
        () => {
          refresh!();
        },
        () => {
          setError(
            new Error('Failed to initialize manifest. Try again later.'),
          );
        },
      );
    } catch (e) {
      setError(new Error('Failed to initialize manifest. Try again later.'));
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackbar}
        onClose={onCloseSnackbar}
        autoHideDuration={error ? SNACKBAR_DURATION * 3 : SNACKBAR_DURATION}
        className={classes.snackbar}
      >
        {error ? (
          <Alert severity="error">
            Something went wrong. {error.toString()}
          </Alert>
        ) : (
          <Alert severity="success">Product successfully initialized.</Alert>
        )}
      </Snackbar>
      <Button
        variant="contained"
        color="primary"
        startIcon={
          isLoading ? (
            <CircularProgress size={20} className={classes.circularProgress} />
          ) : (
            <LineStartCircleIcon />
          )
        }
        onClick={onClick}
        disabled={isLoading || !entity}
      >
        Initialize
      </Button>
    </>
  );
};
