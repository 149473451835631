import React, { useEffect, useState, useCallback } from 'react';

// these css variables are usefull to animate the text collapse/expand dynamically

/**
 * CSS variable to store the clientHeight of the element.
 *
 * @public
 */
export const clientHeightCssVariable = '--client-height';

/**
 * CSS variable to store the scrollHeight of the element.
 *
 * @public
 */
export const scrollHeightCssVariable = '--scroll-height';

/**
 * Hook to determine if a text element is clamped and return the necessary css variables to animate
 * the text collapse/expand.
 *
 * @public
 */
export function useLineClamp<T extends HTMLElement>(
  ref: React.RefObject<T>,
  lines: number,
) {
  const [isClamped, setIsClamped] = useState<boolean>(false);
  const [cssVarStyle, setCssVarStyle] = useState<React.CSSProperties>({});

  const checkClamp = useCallback(() => {
    if (!ref.current) return;

    const element = ref.current;
    const initialValues = {
      webkitLineClamp: element.style.webkitLineClamp,
      display: element.style.display,
      overflow: element.style.overflow,
    };

    element.style.webkitLineClamp = `${lines}`;
    element.style.display = '-webkit-box';
    element.style.overflow = 'hidden';

    const isCurrentlyClamped = element.clientHeight !== element.scrollHeight;
    setIsClamped(isCurrentlyClamped);

    if (isCurrentlyClamped) {
      setCssVarStyle({
        [clientHeightCssVariable]: `${element.clientHeight}px`,
        [scrollHeightCssVariable]: `${element.scrollHeight}px`,
      } as React.CSSProperties);
    }

    Object.assign(element.style, initialValues);
  }, [ref, lines]);

  useEffect(() => {
    const debouncedCheckClamp = () => requestAnimationFrame(checkClamp);

    checkClamp();
    window.addEventListener('resize', debouncedCheckClamp);
    return () => window.removeEventListener('resize', debouncedCheckClamp);
  }, [checkClamp]);

  return {
    isClamped,
    cssVarStyle,
  };
}
