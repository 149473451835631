import { Divider, makeStyles, Typography } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

const useDeployProductButtonSectionStyles = makeStyles(theme => ({
  sectionTitle: {
    textTransform: 'uppercase',
  },
  sectionTitleDivider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
}));

interface DeployProductButtonSectionProps {
  title: string;
}

export const DeployProductButtonSection = ({
  title,
  children,
}: PropsWithChildren<DeployProductButtonSectionProps>) => {
  const classes = useDeployProductButtonSectionStyles();

  return (
    <>
      <Typography variant="overline" className={classes.sectionTitle}>
        {title}
      </Typography>
      <Divider className={classes.sectionTitleDivider} />
      {children}
    </>
  );
};
