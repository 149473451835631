import { type Entity } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { type Metric } from '@internal/backstage-plugin-adeo-core-components-react';
import { getOwnerMetrics } from './getOwnerMetrics';
import { getNamespaceMetrics } from './getNamespaceMetrics';
import { getPackageMetrics } from './getPackageMetrics';
import { getParentProductMetrics } from './getParentProductMetrics';
import { getProductsMetrics } from './getProductsMetrics';
import { getComponentsMetrics } from './getComponentsMetrics';
import { getResourcesMetrics } from './getResourcesMetrics';
import { getLinkMetrics } from './getLinkMetrics';
import { useCachedRelatedEntities } from '../useCachedRelatedEntities';
import { useDelayedLoading } from '../useDelayedLoading';

/**
 * Delayed loading time in milliseconds
 */

export type MetricsKey = keyof typeof getMetricsMap;

type UseMetricsType = (
  entity: Entity,
  metricsKeysToDisplay?: (MetricsKey | MetricsMapFunction)[],
) => {
  loading: boolean;
  metrics: Metric[];
  error?: Error;
};

export type MetricsMapFunction = (
  entity: Entity,
  relatedEntities: Entity[],
  getEntityRoute: (entity: Entity) => string | null,
) => {
  metrics: Metric[];
  error?: Error;
};

type GenerateEntityRoute = ({
  kind,
  namespace,
  name,
}: {
  kind: string;
  namespace: string;
  name: string;
}) => string;

const getEntityRouteFactory =
  (generateEntityRoute: GenerateEntityRoute) => (entity: Entity) => {
    if (!entity.metadata?.namespace) {
      return null;
    }

    return generateEntityRoute({
      kind: entity.kind,
      namespace: entity.metadata.namespace,
      name: entity.metadata.name,
    });
  };

const getMetricsMap = {
  owner: getOwnerMetrics,
  namespace: getNamespaceMetrics,
  package: getPackageMetrics,
  parentProduct: getParentProductMetrics,
  products: getProductsMetrics,
  components: getComponentsMetrics,
  resources: getResourcesMetrics,
  link: getLinkMetrics,
} as const satisfies {
  [key: string]: MetricsMapFunction;
};

const getMetrics = (
  metricsKeyOrFunction: MetricsKey | MetricsMapFunction,
  entity: Entity,
  relatedEntities: Entity[] | undefined,
  getEntityRoute: (entity: Entity) => string | null,
) => {
  if (!relatedEntities)
    return { metrics: [], error: new Error('No related entities found') };
  return typeof metricsKeyOrFunction === 'string'
    ? getMetricsMap[metricsKeyOrFunction](
        entity,
        relatedEntities,
        getEntityRoute,
      )
    : metricsKeyOrFunction(entity, relatedEntities, getEntityRoute);
};

/**
 * Hook to get metrics for an entity
 * @param entity The entity to get metrics for
 * @param metricsKeysToDisplay The metrics keys to display
 * @returns { loading: boolean, metrics: Metric[], error?: string } The loading state, metrics and error message
 */
export const useMetrics: UseMetricsType = (
  entity,
  metricsKeysToDisplay = ['owner', 'namespace'],
) => {
  const generateEntityRoute = useRouteRef(entityRouteRef);
  const getEntityRoute = getEntityRouteFactory(generateEntityRoute);
  const {
    isFetching: loading,
    data: entities,
    error,
  } = useCachedRelatedEntities(
    entity,
    {},
    {
      refetchOnMount: query => {
        // refetch if data is empty, usually happens on the page load
        return !query.state.data?.length;
      },
    },
  );

  const delayedLoading = useDelayedLoading(loading);

  if (delayedLoading || loading) return { loading: true, metrics: [] };

  let metricsToDisplay: Metric[] = [];

  if (error)
    return {
      loading: false,
      metrics: [],
      error: new Error(`Failed to load metrics: ${error}`),
    };

  try {
    metricsToDisplay = metricsKeysToDisplay.flatMap(key => {
      const { metrics, error: err } = getMetrics(
        key,
        entity,
        entities,
        getEntityRoute,
      );

      if (err) throw err;
      return metrics;
    });
  } catch (err: any) {
    return {
      loading: false,
      metrics: [],
      error: new Error(`Failed to load metrics: ${err.message}`),
    };
  }

  return { loading: false, metrics: metricsToDisplay };
};
