const message = 'Missing DBAPI projectId or serviceId';

export class MissingConfigurationError extends Error {
  constructor() {
    super(message);

    this.name = this.constructor.name;
    this.message = message;
  }
}
