import { type Tag } from '@internal/backstage-plugin-adeo-core-components-react';
import {
  BlockIcon,
  LineStartCircleIcon,
  TaskAltIcon,
} from 'backstage-plugin-icons-react';

const defaultConfig = {
  color: 'default',
  variant: 'default',
} as const;

const lifecycleTags = {
  declared: {
    label: 'Not initialized',
    icon: BlockIcon,
  },
  initialized: {
    label: 'Initialized',
    variant: 'outlined',
    icon: LineStartCircleIcon,
  },
  ready: {
    label: 'Ready',
    variant: 'outlined',
    icon: TaskAltIcon,
  },
  deprecated: {
    label: 'Deprecated',
    icon: BlockIcon,
  },
  unknown: {
    label: 'Unknown',
    icon: BlockIcon,
  },
} as const satisfies {
  [key: string]: Tag;
};

export type LifecycleStatus = keyof typeof lifecycleTags;

export const validateLifecycleStatus = (lifecycle: string): LifecycleStatus => {
  if (lifecycle.toLowerCase() in lifecycleTags) {
    return lifecycle as LifecycleStatus;
  }
  return 'unknown';
};

export const getLifecycleTag = (lifecycle: string): Tag => {
  const { label, icon, ...rest } =
    lifecycleTags[validateLifecycleStatus(lifecycle)];
  return {
    label,
    icon,
    ...defaultConfig,
    ...rest,
  };
};
