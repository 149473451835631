import React from 'react';
import JiraIcon from '../icons/JiraIcon';
import GitHubIcon from '../icons/GitHubIcon';
import { Tool } from '@backstage/plugin-home';

export const shortcuts: Tool[] = [
  {
    url: 'https://jira.adeo.com',
    label: 'Jira',
    icon: <JiraIcon height="3rem" width="3rem" />,
  },
  {
    url: 'https://github.com/adeo',
    label: 'GitHub',
    icon: <GitHubIcon height="3rem" width="3rem" isThemeAdaptative />,
  },
];
