import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { DbApiService } from '../models/DbApiService';
import { FailedToFetchError } from './errors/FailedToFetchError';
import { MissingConfigurationError } from './errors/MissingConfigurationError';

interface DbApiResponse<T> {
  _metadata: {
    status: number;
    message: string;
    uuid: string;
    gcp_trace_id: string;
    gcp_span_id: string;
  };
  item: T;
}

export interface DbApiApi {
  getService: (projectId?: string, serviceId?: string) => Promise<DbApiService>;
}

export const dbApiRef = createApiRef<DbApiApi>({
  id: 'plugin.dbapi-api.service',
});

export class DbApiClient implements DbApiApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  private async fetch<T = any>(input: string): Promise<T> {
    const proxyUri = `${await this.discoveryApi.getBaseUrl('proxy')}/dbapi/api`;

    const resp = await this.fetchApi.fetch(`${proxyUri}${input}`);

    if (!resp.ok) {
      throw new FailedToFetchError(resp.status, resp.statusText);
    }

    return await resp.json();
  }

  async getService(
    projectId?: string,
    serviceId?: string,
  ): Promise<DbApiService> {
    if (!projectId || !serviceId) {
      throw new MissingConfigurationError();
    }

    const response = await this.fetch<DbApiResponse<DbApiService>>(
      `/projects/${projectId}/services/${serviceId}`,
    );

    return response.item;
  }
}
