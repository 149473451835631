import { makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';

const useTabsSectionStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
  tabTitleSelected: {
    color: `${theme.palette.text.primary} !important`,
  },
  tabTitle: {
    color: theme.palette.text.disabled,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '&:hover': {
      background: theme.palette.action.hover,
      color: theme.palette.text.disabled,
    },
  },
}));

interface TabsSectionProps {
  tabsTitle: { label: string; value: string }[];
  renderContent: (currentTab: string) => React.ReactNode;
}

export const TabsSection = ({ tabsTitle, renderContent }: TabsSectionProps) => {
  const classes = useTabsSectionStyles();
  const [currentTab, setCurrentTab] = React.useState(tabsTitle[0].value);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <Paper square className={classes.root} elevation={0}>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        {tabsTitle.map((tabTitle, index) => (
          <Tab
            key={index}
            label={<Typography variant="body2">{tabTitle.label}</Typography>}
            value={tabTitle.value}
            classes={{
              selected: classes.tabTitleSelected,
              root: classes.tabTitle,
            }}
          />
        ))}
      </Tabs>
      {renderContent(currentTab)}
    </Paper>
  );
};
