import { StateCreator } from 'zustand';
import { Entity } from '@backstage/catalog-model';
import { DeploymentStatus } from '../DeploymentAccordion/useDeploymentStatus';
import {
  MetricsKey,
  MetricsMapFunction,
} from '../../../../../../../hooks/metrics/useMetrics';
import { SubscriptionEntity } from '../../../../../../../types/SubscriptionEntity';
import { getImageTagMetrics } from '../../../../../../../hooks/metrics/deployed-entities/getImageTagMetrics';
import { getDBAPIProjectMetrics } from '../../../../../../../hooks/metrics/deployed-entities/getDBAPIProjectMetrics';
import { getResourceVersionMetrics } from '../../../../../../../hooks/metrics/deployed-entities/getResourceVersionMetrics';
import { getResourcePlanMetrics } from '../../../../../../../hooks/metrics/deployed-entities/getResourcePlanMetrics';
import { getResourceNodesMetrics } from '../../../../../../../hooks/metrics/deployed-entities/getResourceNodesMetrics';
import { getScheduleMetrics } from '../../../../../../../hooks/metrics/deployed-entities/getScheduleMetrics';

const getEntityMetrics = (
  entityType: string,
): (MetricsKey | MetricsMapFunction)[] => {
  switch (entityType) {
    case 'api':
    case 'frontend':
      return [getImageTagMetrics];
    case 'postgresql':
      return [
        getDBAPIProjectMetrics,
        getResourceVersionMetrics,
        getResourcePlanMetrics,
      ];
    case 'mongodb':
      return [
        getDBAPIProjectMetrics,
        getResourceVersionMetrics,
        getResourcePlanMetrics,
        getResourceNodesMetrics,
      ];
    case 'cronjob':
      return [getScheduleMetrics, getImageTagMetrics];
    default:
      return [];
  }
};

type State = {
  open: boolean;
  entity?: Entity;
  subscription?: SubscriptionEntity;
  productVersion?: Entity;
  entityMetrics?: (MetricsKey | MetricsMapFunction)[];
  status?: DeploymentStatus;
};

const initialState: State = {
  open: false,
  entity: undefined,
  subscription: undefined,
  productVersion: undefined,
};

type Actions = {
  openDrawer: (
    entity: Entity,
    subscriptionWithVersion: SubscriptionEntity,
    productVersion: Entity,
  ) => void;
  changeEntity: (entity: Entity) => void;
  closeDrawer: () => void;
};

export type DeploymentDrawerSlice = State & Actions;

export const createDeploymentDrawerSlice: StateCreator<
  DeploymentDrawerSlice
> = (set, get) => ({
  ...initialState,
  openDrawer: (entity, subscription, productVersion) =>
    set({
      open: true,
      entity,
      subscription,
      productVersion,
      entityMetrics: getEntityMetrics(entity.spec?.type as string),
    }),
  changeEntity: entity => {
    if (!get().open || !get().subscription || !get().productVersion)
      throw new Error('Cannot change drawer entity when drawer is closed');

    set({
      entity,
      entityMetrics: getEntityMetrics(entity.spec?.type as string),
    });
  },
  closeDrawer: () => set(initialState),
});
