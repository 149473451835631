import { EntitySwitch, isResourceType } from '@backstage/plugin-catalog';
import React from 'react';
import { DefaultEntityPage } from '../DefaultEntityPage';
import { DatabasePage } from './DatabasePage';

export const ResourcePage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isResourceType('postgresql')}>
      {DatabasePage}
    </EntitySwitch.Case>
    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
