import { TabContent } from '../SectionContent';
import { useDeploymentsStore } from '../../../store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../manifest-utils';
import { ComponentManifest } from '../../../../../../../../../types/applied-product-manifest/AppliedProductManifest';

export const useComponentConfig = ():
  | { error: Error }
  | { data: TabContent } => {
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);

  if (!entity || !subscription || !productVersion)
    return {
      error: new Error(
        'Could not find component config because either component, subscription or productVersion is missing',
      ),
    };

  const componentManifestOrError =
    getComponentOrResourceAppliedManifest<ComponentManifest>(
      entity,
      subscription,
      productVersion,
    );

  if ('error' in componentManifestOrError) return componentManifestOrError;

  const config = componentManifestOrError?.env;

  const formattedConfig = {
    config: {
      data: config?.map(env => `${env.name}="${env.value}"`) ?? [],
    },
  };

  return { data: formattedConfig };
};
