const STORAGE_KEY = 'recentlyVisitedEntities';
const STORAGE_SIZE = 30;

export type RecentlyVisitedEntity = {
  entityRef: string;
  pathname: string;
};

class RecentlyVisitedEntitiesApi {
  list(): RecentlyVisitedEntity[] {
    const rawRecentlyVisitedEntities =
      localStorage.getItem(STORAGE_KEY) ?? '[]';

    try {
      return JSON.parse(rawRecentlyVisitedEntities);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'Failed to parse recently visited entities from local storage',
        rawRecentlyVisitedEntities,
      );
      return [];
    }
  }

  save(newEntry: RecentlyVisitedEntity) {
    if (!newEntry.entityRef || !newEntry.pathname) {
      // eslint-disable-next-line no-console
      console.error(
        `Cannot save entity with missing entityRef or pathname`,
        newEntry,
      );
      return;
    }

    const allEntities = this.list();

    if (
      !allEntities.find(
        existingEntry =>
          existingEntry.entityRef.toLowerCase() ===
          newEntry.entityRef.toLowerCase(),
      )
    ) {
      allEntities.unshift(newEntry);
    }

    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(allEntities.slice(0, STORAGE_SIZE)),
    );
  }

  delete(entityRef: string) {
    const allEntities = this.list();

    const existingEntryIndex = allEntities.findIndex(
      existingEntry => existingEntry.entityRef === entityRef,
    );

    if (existingEntryIndex !== -1) {
      allEntities.splice(existingEntryIndex, 1);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(allEntities));
    }
  }
}

export default new RecentlyVisitedEntitiesApi();
