import React, { PropsWithChildren, useEffect } from 'react';
import { makeStyles, styled } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import Logo from './Logo';
import { UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarGroup,
  SidebarPage,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { CustomSidebarItem } from './sidebar/CustomSidebarItem';
import { RecentlyVisitedEntities } from './sidebar/recently-visited/RecentlyVisitedEntities';
import { Inventory2SearchIcon } from 'backstage-plugin-icons-react';
import { useLocation } from 'react-router';
import { usePiano } from '../../hooks/usePianoSendEvent';

const useSidebarLogoStyles = makeStyles(theme => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: -theme.spacing(3),
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: theme.spacing(4.5),
  },
}));

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        <Logo isTextDisplayed={isOpen} />
      </Link>
    </div>
  );
};

const SidebarDivider = styled('hr')(({ theme }) => ({
  height: 1,
  width: '100%',
  background: 'rgba(255, 255, 255, 0.12)',
  border: 'none',
  margin: theme.spacing(1.2, 0),
}));

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { pathname } = useLocation();
  const { sendEvent } = usePiano();

  useEffect(() => {
    const page = pathname.split('/')[1];
    if (page !== 'catalog' && page !== '') {
      sendEvent('page.display', page);
    }
  }, [pathname, sendEvent]);

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* HOME */}
          <CustomSidebarItem icon={HomeIcon} to="/" text="Home" exactMatch />
          {/* PACKAGE DISCOVERY */}
          <CustomSidebarItem
            text="Package discovery"
            icon={Inventory2SearchIcon}
            to="/package-discovery"
            exactMatch
          />
          {/* CATALOG */}
          <CustomSidebarItem
            icon={CategoryIcon}
            to="/catalog"
            text="Catalog"
            exactMatch
          />
        </SidebarGroup>
        {/* CREATE */}
        <SidebarDivider />
        <CustomSidebarItem
          icon={CreateComponentIcon}
          to="/create"
          text="Create"
          exactMatch
        />
        <SidebarDivider style={{ marginBottom: 0 }} />
        <RecentlyVisitedEntities />
        {/* SETTINGS */}
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <CustomSidebarItem
            icon={SettingsIcon}
            to="/settings"
            text="Settings"
            exactMatch
          />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
