import React from 'react';
import { UserEntity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { HomeIcon, PersonIcon } from 'backstage-plugin-icons-react';
import { usePianoSendEvent } from '../../../../hooks/usePianoSendEvent';

const UserEntityBreadcrumb = () => {
  const { entity } = useEntity<UserEntity>();
  usePianoSendEvent('page.display', 'user');

  return (
    <Breadcrumbs>
      <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
      <BreadcrumbsItems
        label={entity.spec.profile?.displayName ?? entity.metadata.name ?? ''}
        Icon={PersonIcon}
        isLast
      />
    </Breadcrumbs>
  );
};

UserEntityBreadcrumb.displayName = 'UserEntityBreadcrumb';

export { UserEntityBreadcrumb };
