import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

const SVG_WIDTH = 200;

export type EmptyStateProps = {
  title: string;
  description: string;
  illustration: ReactElement<SVGElement>;
  action?: ReactElement;
};

const useEmptyStateStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    maxWidth: 400,
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  illustration: {
    width: `${SVG_WIDTH}px`,
    height: 'auto',
  },
}));

export const EmptyState = ({
  title,
  description,
  illustration,
  action,
}: EmptyStateProps) => {
  const classes = useEmptyStateStyles();

  const resizedIllustration = React.cloneElement(illustration, {
    className: classes.illustration,
  });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {resizedIllustration}
        <Typography variant="h5" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {description}
        </Typography>
        {action}
      </div>
    </div>
  );
};
