import RecentlyVisitedEntitiesApi, {
  RecentlyVisitedEntity,
} from './RecentlyVisitedEntitiesApi';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useEffect, useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { classNames as cn } from '../../../../utils/classNames';
import CloseIcon from '@material-ui/icons/Close';
import { CustomSidebarItem } from '../CustomSidebarItem';
import { getEntityDisplayName, getEntityOverline } from './utils';
import { useLocation } from 'react-router-dom';
import { getEntityIcon } from '@internal/backstage-plugin-adeo-core-components-react';

const useRecentlyVisitedItemStyle = makeStyles(theme => ({
  recentlyVisitedItem: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover $closeIconContainer': {
      display: 'flex',
    },
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '95%',
  },
  overlineLabel: {
    lineHeight: 'initial',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  closeIconContainer: {
    display: 'none',
    alignItems: 'center',
  },
  closeIcon: {
    marginRight: theme.spacing(2),
    fontSize: '1.2rem',
    '&:hover': {
      color: theme.palette.navigation.selectedColor,
    },
  },
}));

export const RecentlyVisitedItem = ({
  visit,
  onDelete,
}: {
  visit: RecentlyVisitedEntity;
  onDelete: (entityRef: string, wasSelected: boolean) => void;
}) => {
  const { entityRef, pathname: entityPathname } = visit;
  const catalogApi = useApi(catalogApiRef);
  const { pathname } = useLocation();
  const [entity, setEntity] = useState<Entity | undefined>();
  const classes = useRecentlyVisitedItemStyle();

  useEffect(() => {
    catalogApi.getEntityByRef(entityRef).then(e => setEntity(e));
  }, [catalogApi, entityRef]);

  const onClickClose = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault();
    RecentlyVisitedEntitiesApi.delete(entityRef);
    onDelete(entityRef, pathname.startsWith(entityPathname));
  };

  if (!entity) {
    return null;
  }

  const TextComponent = (
    <Box component="span" className={classes.recentlyVisitedItem}>
      <Box component="span" className={classes.labelContainer}>
        <Typography
          variant="overline"
          className={cn(classes.overlineLabel, classes.label)}
        >
          {getEntityOverline(entity)}
        </Typography>
        <Typography variant="subtitle2" className={classes.label}>
          {getEntityDisplayName(entity)}
        </Typography>
      </Box>
      <Box component="span" className={classes.closeIconContainer}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={onClickClose}
          data-testid={`close-icon-${entityRef}`}
        />
      </Box>
    </Box>
  );

  return (
    <CustomSidebarItem
      text={TextComponent}
      icon={getEntityIcon(entity)}
      to={entityPathname}
    />
  );
};
