import React, { ReactNode } from 'react';
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  EntityListProvider,
  EntityOwnerPickerProps,
  EntityTypePicker,
  UserListFilterKind,
} from '@backstage/plugin-catalog-react';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';

export interface CatalogPageProps {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<CatalogTableRow>[] | undefined;
  actions?: TableProps<CatalogTableRow>['actions'];
  initialKind?: string;
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  ownerPickerMode?: EntityOwnerPickerProps['mode'];
  pagination?: boolean | { limit?: number };
}

export const ProductsListPage = ({ columns, actions }: CatalogPageProps) => {
  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  return (
    <PageWithHeader title={`${orgName} Catalog`} themeId="home">
      <EntityListProvider>
        <Content>
          <ContentHeader title="Manage your entities">
            <SupportButton>All your software entities</SupportButton>
          </ContentHeader>
          <EntityTypePicker initialFilter="product" />
          <CatalogTable columns={columns} actions={actions} />
        </Content>
      </EntityListProvider>
    </PageWithHeader>
  );
};
