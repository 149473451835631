import { useDeploymentsStore } from '../../../../store/deployments-store';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';
import {
  GenericSecretsContentHeader,
  SecretsContentHeaderProps,
} from '../GenericSecretsContentHeader';

export type ComponentSecretsContentHeaderProps = SecretsContentHeaderProps & {
  onLoadingChange: (loading: boolean) => void;
};

export const ComponentSecretsContentHeader = ({
  onChange,
  defaultResourceName,
  onLoadingChange,
  ...restProps
}: ComponentSecretsContentHeaderProps) => {
  const productVersion = useDeploymentsStore(state => state.productVersion!);

  const { entities: resources, loading } = useRelatedEntities(productVersion, {
    kind: 'Resource',
  });

  useEffect(() => {
    onLoadingChange(loading);
  }, [loading, onLoadingChange]);

  return (
    <GenericSecretsContentHeader
      onChange={onChange}
      defaultResourceName={defaultResourceName}
      selectorEntities={resources}
      noEntitiesMessage="No resources were found in this product"
      isLoading={loading}
      {...restProps}
    />
  );
};
