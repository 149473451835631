import React, { useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Avatar } from '../../Avatar';
import { AvatarGroup } from '../../AvatarGroup';
import { Divider } from '@material-ui/core';
import { EntityPresentationContext } from '../EntityPresentationContext';
import { EntityPresentationMembersSkeleton } from './EntityPresentationMembersSkeleton';

/**
 * Represents a member of an entity.
 *
 * @public
 */
export type EntityMember = {
  displayName: string;
  email: string;
  job: string;
  avatarImage?: string;
};

/**
 * Props for {@link EntityPresentationMembers}.
 *
 * @public
 */
export type EntityPresentationMembersProps = {
  mainMembers: EntityMember[];
  otherMembers: EntityMember[];
  loading?: boolean;
};

const useEntityPresentationMembersStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  mainContacts: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  members: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
  contacts: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },

  memberItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  labelSmall: {
    fontSize: '10px',
  },
  labelMedium: {
    fontSize: '12px',
  },
  membersDisplay: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    gap: theme.spacing(1),
  },
}));

/**
 * Displays the members of an entity presentation.
 *
 * @remarks
 *
 * This component displays the members of an entity presentation. There are two sections: main
 * contacts and members. Each section displays a list of members with their avatars. The main
 * contacts are displayed first, followed by the other members. This component is intended to be
 * used within an {@link EntityPresentation}.
 *
 * @public
 */
export const EntityPresentationMembers = (
  props: EntityPresentationMembersProps,
) => {
  const { mainMembers, otherMembers, loading } = props;
  const classes = useEntityPresentationMembersStyles();
  const { size } = useContext(EntityPresentationContext);

  const labelClasses = useMemo(() => {
    return `${classes.label} ${size === 'small' ? classes.labelSmall : classes.labelMedium}`;
  }, [classes, size]);

  const allMembers = [...mainMembers, ...otherMembers];

  if (loading) {
    return <EntityPresentationMembersSkeleton />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainContacts}>
        <Typography className={labelClasses}>Main contacts</Typography>
        {mainMembers.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            No main contacts
          </Typography>
        ) : (
          <div className={classes.contacts}>
            {mainMembers.map((member, index) => (
              <div key={member.email} className={classes.memberItem}>
                <Avatar size={size} isCompact={false} {...member} />
                {index < mainMembers.length - 1 && (
                  <Divider orientation="vertical" flexItem />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={classes.members}>
        <Typography className={labelClasses}>Members</Typography>
        {allMembers.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            No members
          </Typography>
        ) : (
          <div className={classes.membersDisplay}>
            <AvatarGroup size={size} users={allMembers} max={3} />
          </div>
        )}
      </div>
    </div>
  );
};
