import { create } from 'zustand';
import {
  createDeploymentsFiltersSlice,
  DeploymentsFiltersSlice,
} from './deployments-filters-slice';
import {
  createDeploymentDrawerSlice,
  DeploymentDrawerSlice,
} from './deployment-drawer-slice';
import { devtools } from 'zustand/middleware';
import {
  createSubscriptionsSlice,
  SubscriptionsSlice,
} from './subscriptions-slice';

export type DeploymentsStore = DeploymentsFiltersSlice &
  DeploymentDrawerSlice &
  SubscriptionsSlice;

export const useDeploymentsStore = create<DeploymentsStore>()(
  devtools((...a) => ({
    ...createDeploymentsFiltersSlice(...a),
    ...createDeploymentDrawerSlice(...a),
    ...createSubscriptionsSlice(...a),
  })),
);
