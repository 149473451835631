import {
  Entity,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { useEffect, useMemo, useState } from 'react';

export interface DeployedVersion {
  name: string;
  entityRef: string;
  subscriptions: Entity[];
  deployments: string[];
}

export const useDeployedVersions = (versions?: Entity[]): DeployedVersion[] => {
  const catalogApi = useApi(catalogApiRef);
  const [subscriptions, setSubscriptions] = useState<Entity[]>([]);

  // Get possible entityRefs of subscriptions, some of them might be refs to other entities
  const dependencyOfRelationsRefs = useMemo<string[]>(() => {
    if (!versions) return [];

    return versions.flatMap(version =>
      getEntityRelations(version, RELATION_DEPENDENCY_OF).map(
        stringifyEntityRef,
      ),
    );
  }, [versions]);

  // fetch entities of dependencyOfRelationsRefs
  useEffect(() => {
    if (dependencyOfRelationsRefs.length === 0) return;

    catalogApi
      .getEntitiesByRefs({ entityRefs: dependencyOfRelationsRefs })
      .then(({ items }) => {
        const entities = items.filter((item): item is Entity => Boolean(item));
        setSubscriptions(entities.filter(e => e.spec?.type === 'subscription'));
      });
  }, [catalogApi, dependencyOfRelationsRefs]);

  return useMemo(() => {
    if (!versions) return [];

    return (
      versions
        // Map versions to subscription entities
        .map(version => {
          const relatedSubscriptions = subscriptions.filter(subscription =>
            subscription.relations?.some(
              relation =>
                relation.type === RELATION_DEPENDS_ON &&
                relation.targetRef === stringifyEntityRef(version),
            ),
          );

          return {
            name: version.metadata.title ?? version.metadata.name,
            subscriptions: relatedSubscriptions,
            entityRef: stringifyEntityRef(version),
            deployments: relatedSubscriptions.map(
              subscription =>
                subscription.metadata.annotations?.['aap.adeo.cloud/project'],
            ),
          };
        })
    );
  }, [subscriptions, versions]);
};
