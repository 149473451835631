import React, { useMemo } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  EntityPresentation,
  EntityPresentationHeader,
  EntityPresentationMetrics,
  Tag,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { Alert } from '@material-ui/lab';
import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { useMetrics } from '../../../../../../../hooks/metrics/useMetrics';
import { ComponentAndResourceCard } from './ComponentAndResourceCard';
import { useProductSubscriptionsAndVersions } from '../../../../../hooks/useProductSubscriptionsAndVersions';
import { GithubIcon } from 'backstage-plugin-icons-react';

const useProductVersionEntityOverviewStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ProductVersionEntityOverview = () => {
  const classes = useProductVersionEntityOverviewStyle();
  const { entity } = useEntity();

  const subscriptions = useProductSubscriptionsAndVersions(entity);

  const { entities: componentEntities, error: componentError } =
    useRelatedEntities(entity, {
      kind: 'component',
      type: RELATION_DEPENDS_ON,
    });

  const { entities: resourceEntities, error: resourceError } =
    useRelatedEntities(entity, {
      kind: 'resource',
      type: RELATION_DEPENDS_ON,
    });

  const {
    loading: loadingMetrics,
    error: errorMetrics,
    metrics,
  } = useMetrics(entity, ['parentProduct', 'components', 'resources']);

  const tags = useMemo(() => {
    const sourceLocation =
      entity.metadata?.annotations?.['backstage.io/source-location'].replace(
        'url:',
        '',
      ) ?? '';

    const subscriptionTag = subscriptions?.map(({ subscription }) => {
      const project =
        subscription?.metadata?.annotations?.['aap.adeo.cloud/project'];
      return {
        label: (
          <>
            Deployed in{' '}
            <Box component="span" fontWeight="fontWeightBold">
              {project}
            </Box>
          </>
        ),
        variant: 'outlined',
        color: 'primary',
      };
    });
    return [
      ...subscriptionTag,
      {
        icon: GithubIcon,
        variant: 'outlined',
        label: sourceLocation.replace('https://github.com/', ''),
        link: sourceLocation,
      },
    ] as Tag[];
  }, [entity, subscriptions]);

  const type = 'Product Version';

  const errors = componentError ?? resourceError;

  const componentsAndResource = [
    ...(componentEntities ?? []),
    ...(resourceEntities ?? []),
  ];

  return (
    <Container maxWidth="lg" className={classes.root}>
      <EntityPresentation size="medium">
        <EntityPresentationHeader
          title={entity.metadata.title ?? ''}
          type={type}
          tags={tags}
        />
        <Divider />
        {errorMetrics ? (
          <Alert severity="error" className={classes.errorContainer}>
            {errorMetrics.message}
          </Alert>
        ) : (
          <EntityPresentationMetrics
            metrics={metrics}
            loading={loadingMetrics}
          />
        )}
        <Divider />
      </EntityPresentation>
      <FormControl variant="outlined" color="primary">
        <InputLabel id="demo-simple-select-outlined-label">
          Subscription type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Subscription type"
          value="dev"
        >
          <MenuItem value="dev">Development</MenuItem>
          <MenuItem value="nprd">Non-production</MenuItem>
          <MenuItem value="standard">Standard Usage</MenuItem>
          <MenuItem value="performance">Performance usage</MenuItem>
        </Select>
      </FormControl>
      {errors && (
        <Alert severity="error" className={classes.errorContainer}>
          {errors.message}
        </Alert>
      )}
      {componentsAndResource && componentsAndResource.length > 0 && (
        <Grid container spacing={2}>
          {componentsAndResource.map(onDemandsEntity => (
            <Grid
              item
              key={onDemandsEntity.metadata.uid}
              xs={12}
              sm={12}
              md={12}
              lg={6}
            >
              <ComponentAndResourceCard entity={onDemandsEntity} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export { ProductVersionEntityOverview };
