import React from 'react';
import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  styled,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { AboutField } from '@backstage/plugin-catalog';
import { FullHeightCard } from '../../../../../utils/FullHeightCard';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

type Statuses =
  | 'SyncFailed'
  | 'OutOfSync'
  | 'Syncing'
  | 'Synced'
  | 'Missing'
  | 'Degraded'
  | 'Suspended'
  | 'Progressing'
  | 'Healthy'
  | 'Unknown';

const green = '#00B58B';
const red = '#E9616D';
const orange = '#F5B63F';
const gray = '#C5D0D8';

const statusColor: Record<Statuses, string> = {
  SyncFailed: red,
  OutOfSync: red,
  Missing: red,
  Degraded: red,
  Suspended: red,
  Syncing: orange,
  Progressing: orange,
  Synced: green,
  Healthy: green,
  Unknown: gray,
};

const StatusIndicator = ({ status }: { status: Statuses }) => {
  return (
    <span
      style={{
        borderRadius: '50%',
        width: '.75rem',
        height: '.75rem',
        backgroundColor: statusColor[status],
        content: ' ',
        display: 'inline-block',
      }}
    />
  );
};

const FlexCenteredSpan = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '.25rem',
});

const StatusCardProgressBar = withStyles({
  root: {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
  },
})(LinearProgress);

interface SubscriptionStatusProps {
  entity: Entity;
}

export const SubscriptionStatus = ({ entity }: SubscriptionStatusProps) => {
  const configApi = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);

  const statuses = useAsync(async () => {
    const backendUrl = configApi.getString('backend.baseUrl');

    const response = await fetch(
      `${backendUrl}/api/aap-argocd/subscription?selector=aap.adeo.cloud/subscription-id=${entity.metadata.name}`,
    );

    if (!response.ok)
      throw Error(
        `Failed to fetch status from Argo (${response.status} ${response.statusText})`,
      );

    return response.json();
  });

  const isInProgress =
    !statuses.error &&
    (statuses.loading ||
      statuses.value?.status.health === 'Progressing' ||
      statuses.value?.status.sync === 'Syncing');

  return (
    <FullHeightCard>
      <CardHeader
        title={
          <>
            <Typography variant="h5">Status</Typography>
            {isInProgress && <StatusCardProgressBar />}
          </>
        }
        style={{ position: 'relative' }}
      />
      <Divider />
      <CardContent>
        <Grid container>
          {!statuses.error ? (
            <>
              <AboutField label="Health status" gridSizes={{ sm: 4 }}>
                {!statuses.loading && (
                  <FlexCenteredSpan>
                    <StatusIndicator status={statuses.value?.status.health} />{' '}
                    {statuses.value?.status.health}
                  </FlexCenteredSpan>
                )}
              </AboutField>
              <AboutField label="Sync status" gridSizes={{ sm: 4 }}>
                {!statuses.loading && (
                  <FlexCenteredSpan>
                    <StatusIndicator status={statuses.value?.status.sync} />{' '}
                    {statuses.value?.status.sync}
                  </FlexCenteredSpan>
                )}
              </AboutField>
            </>
          ) : (
            <Grid item>
              <Typography variant="body2" color="error">
                {statuses.error.toString()}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </FullHeightCard>
  );
};
