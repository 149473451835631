import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ComponentSecrets } from '../common/secrets/ComponentSecrets/ComponentSecrets';
import { ComponentConfig } from '../common/ComponentConfig/ComponentConfig';

const useCronjobDeploymentContentStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export const CronjobDeploymentContent = () => {
  const classes = useCronjobDeploymentContentStyles();

  return (
    <div className={classes.root} data-testid="cronjob-drawer-content">
      <ComponentConfig />
      <ComponentSecrets />
    </div>
  );
};
