import { StatusBadge } from '../StatusBadge';
import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';

interface RenderArgoStatusFilterValueProps {
  title: string;
  inputStatuses: string[];
  statuses: string[];
}

const useRenderArgoStatusFilterValueStyle = makeStyles(theme => ({
  badge: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(-1.5),
    },
  },
  statusesValueContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusesValueChip: {
    margin: 0,
    padding: 0,
    marginLeft: 'auto',
  },
  selectEmptyText: {
    fontStyle: 'italic',
  },
}));

export const RenderArgoStatusFilterValue = ({
  inputStatuses,
  statuses,
  title,
}: RenderArgoStatusFilterValueProps) => {
  const classes = useRenderArgoStatusFilterValueStyle();

  if (inputStatuses.length === 0)
    return <span className={classes.selectEmptyText}>No status selected</span>;

  return (
    <div className={classes.statusesValueContainer}>
      {inputStatuses
        .map(status => (
          <StatusBadge
            className={classes.badge}
            status={status}
            key={status}
            data-testid={`chip-${status}`}
          />
        ))
        .slice(0, 5)}
      <span>{title}</span>
      <Chip
        label={`${inputStatuses.length}/${statuses.length}`}
        size="small"
        className={classes.statusesValueChip}
      />
    </div>
  );
};
