import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import chunk from 'lodash.chunk';
import clamp from 'lodash.clamp';
import { Entity } from '@backstage/catalog-model';

const GRID_VIEW_CHUNK_SIZE = 6;
const LIST_VIEW_CHUNK_SIZE = 12;

export const usePagination = (data: Entity[], viewMode: 'grid' | 'list') => {
  const [searchParams, setSearchParams] = useSearchParams();
  const viewModeRef = useRef(viewMode);

  // https://github.com/remix-run/react-router/issues/9991#issuecomment-1550086638
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchParams = useCallback(setSearchParams, []);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const chunkedData = useMemo(
    () =>
      chunk(
        data,
        viewMode === 'grid' ? GRID_VIEW_CHUNK_SIZE : LIST_VIEW_CHUNK_SIZE,
      ),
    [data, viewMode],
  );

  const clampedPageIndex = clamp(currentPage - 1, 0, chunkedData.length - 1);

  const currentChunk = chunkedData[clampedPageIndex];

  const onPageChange = useCallback(
    (page: number) => {
      updateSearchParams(
        new URLSearchParams({
          ...Object.fromEntries(searchParams),
          page: page.toString(),
        }),
      );
      setCurrentPage(page);
    },
    [updateSearchParams, searchParams],
  );

  useEffect(() => {
    const urlPage =
      searchParams.get('page') && Number(searchParams.get('page'));

    if (urlPage) {
      setCurrentPage(urlPage);
    }
  }, [searchParams, updateSearchParams]);

  useEffect(() => {
    // Reset pagination on viewMode change because page sizes are different
    // Use ref to avoid unnecessary updates
    if (viewModeRef.current !== viewMode) {
      updateSearchParams(
        new URLSearchParams({ ...Object.fromEntries(searchParams), page: '1' }),
      );
      setCurrentPage(1);
      viewModeRef.current = viewMode;
    }
  }, [viewMode, updateSearchParams, searchParams]);

  return {
    data: currentChunk,
    onPageChange,
    page: clampedPageIndex + 1,
    pageCount: chunkedData.length,
  };
};
