import { RelationsContent } from '../../../content/RelationsContent';
import React from 'react';

import { TechDocsContent } from '../../../content/TechDocsContent';
import { EntityLayout } from '../../../entity-layout/EntityLayout';
import { PackageEntityOverview } from './overview/PackageEntityOverview';

export const PackageEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <PackageEntityOverview />
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/relations" title="Relations">
      {RelationsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
