import React from 'react';
import { useRouteRef } from '@backstage/core-plugin-api';

import {
  RELATION_DEPENDENCY_OF,
  Entity,
  ComponentEntity,
} from '@backstage/catalog-model';
import {
  useEntity,
  useRelatedEntities,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';

import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import {
  HomeIcon,
  Package2Icon,
  DeployedCodeIcon,
  Inventory2Icon,
} from 'backstage-plugin-icons-react';
import { usePianoSendEvent } from '../../../../hooks/usePianoSendEvent';

type PackageBreadcrumbsItemsProps = {
  productEntity: Entity;
  isCompact?: boolean;
};

export const PackageBreadcrumbsItems = ({
  productEntity,
  isCompact,
}: PackageBreadcrumbsItemsProps) => {
  const generateEntityRoute = useRouteRef(entityRouteRef);
  const { entities, loading, error } = useRelatedEntities(productEntity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  const packageEntity = entities?.find(ent => ent.spec?.type === 'package');

  const packageRoute = generateEntityRoute({
    kind: 'Component',
    namespace: packageEntity?.metadata.namespace ?? '',
    name: packageEntity?.metadata.name ?? '',
  });

  if (loading || error) {
    return null;
  }

  return (
    <BreadcrumbsItems
      label={packageEntity?.metadata.title ?? ''}
      to={packageRoute}
      Icon={Inventory2Icon}
      isCompact={isCompact}
    />
  );
};

export const useProductVersionEntityBreadcrumbData = (
  productVersionEntity: ComponentEntity,
) => {
  const { entities, loading, error } = useRelatedEntities(
    productVersionEntity,
    {
      kind: 'Component',
      type: RELATION_DEPENDENCY_OF,
    },
  );

  const productEntity = entities?.find(ent => ent.spec?.type === 'product');

  const generateEntityRoute = useRouteRef(entityRouteRef);

  const productRoute = generateEntityRoute({
    kind: 'Component',
    namespace: productEntity?.metadata.namespace ?? '',
    name: productEntity?.metadata.name ?? '',
  });

  return { loading, error, productEntity, productRoute };
};

const ProductVersionEntityBreadcrumb = () => {
  const { entity } = useEntity<ComponentEntity>();
  usePianoSendEvent('page.display', 'productVersion');

  const { productEntity, productRoute, loading, error } =
    useProductVersionEntityBreadcrumbData(entity);

  if (loading || error) {
    return null;
  }

  return (
    <Breadcrumbs>
      <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />

      {productEntity && (
        <PackageBreadcrumbsItems productEntity={productEntity} />
      )}

      <BreadcrumbsItems
        label={productEntity?.metadata.title ?? ''}
        to={productRoute}
        Icon={Package2Icon}
      />

      <BreadcrumbsItems
        label={entity.metadata.title ?? ''}
        Icon={DeployedCodeIcon}
        isLast
      />
    </Breadcrumbs>
  );
};

ProductVersionEntityBreadcrumb.displayName = 'ProductVersionEntityBreadcrumb';

export { ProductVersionEntityBreadcrumb };
