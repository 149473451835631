import { Inventory2Icon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getPackageMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  getEntityRoute,
) => {
  if (entity.spec?.type !== 'product')
    return {
      metrics: [
        {
          label: 'Package',
          error: new Error(
            'You cannot display a package for a non-product entity',
          ),
        },
      ],
    };
  const packageParent = relatedEntities.find(
    relatedEntity => relatedEntity.spec?.type === 'package',
  );
  if (!packageParent)
    return {
      metrics: [
        {
          label: 'Package',
          error: new Error(
            `No package entity found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };

  const metric = createMetrics(
    packageParent.metadata?.name,
    'Package',
    Inventory2Icon,
    getEntityRoute(packageParent),
  );
  return { metrics: [metric] };
};
