/**
 * Get a hash from a string
 *
 * @param fullName The string to hash.
 * @returns The hash.
 */
export const getHash = (fullName: string): number => {
  let hash = 0;
  for (let i = 0; i < fullName.length; i++) {
    const char = fullName.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return Math.abs(hash);
};
