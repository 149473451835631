import React from 'react';
import { makeStyles } from '@material-ui/core';

const useDeploymentAccordionTitleStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
  },
  title: {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

interface DeploymentAccordionTitleProps {
  title?: string;
  subtitle?: string;
}

export const DeploymentAccordionTitle = ({
  title,
  subtitle,
}: DeploymentAccordionTitleProps) => {
  const classes = useDeploymentAccordionTitleStyles();

  return (
    <div className={classes.root}>
      <span className={classes.title}>{title}</span>
      <br />
      <span className={classes.secondaryText}>{subtitle}</span>
    </div>
  );
};
