import { useAsync } from 'react-use';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import orderBy from 'lodash.orderby';

type Product = {
  id: string;
  name: string;
  status: string;
  link: string;
};

type ResponseData = {
  products: Product[];
};

export const useGetUserProducts = (ldap: string) => {
  const configApi = useApi(configApiRef);

  const { fetch } = useApi(fetchApiRef);

  return useAsync(async () => {
    const backendUrl = configApi.getString('backend.baseUrl');

    const response = await fetch(
      `${backendUrl}/api/user/${ldap}/tangram_products`,
    );

    if (response.ok) {
      const res = (await response.json()) as ResponseData;
      return orderBy(res.products, 'name');
    }

    throw new Error('Error when retrieving user products');
  }, []);
};
