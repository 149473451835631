import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { DeploymentAccordionStatus } from './AccordionHeader/DeploymentAccordionStatus';
import { DeploymentAccordionTitle } from './AccordionHeader/DeploymentAccordionTitle';
import { DeploymentAccordionGitInfo } from './AccordionHeader/DeploymentAccordionGitInfo';
import { DeploymentAccordionOwner } from './AccordionHeader/DeploymentAccordionOwner';
import { DeploymentAccordionContent } from './AccordionContent/DeploymentAccordionContent';
import { DeploymentAccordionActions } from './AccordionHeader/DeploymentAccordionActions';
import {
  ENTITY_VERSION_COMMIT_ID_ANNOTATION,
  ENTITY_VERSION_COMMIT_MESSAGE_ANNOTATION,
  ENTITY_VERSION_REF_NAME_ANNOTATION,
  SUBSCRIPTION_PROJECT_ANNOTATION,
  SUBSCRIPTION_TYPE_ANNOTATION,
} from 'custom-annotations';
import { useDeploymentStatus } from './useDeploymentStatus';
import { useDeploymentsStore } from '../store/deployments-store';
import { SubscriptionEntity } from '../../../../../../../types/SubscriptionEntity';

import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { InspectEntityDialog } from '@backstage/plugin-catalog-react';
import { useSearchParams } from 'react-router-dom';

const useDeploymentAccordionStyles = makeStyles(theme => ({
  accordion: {
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    alignItems: 'center',
    width: '100%',
  },
  accordionSummaryContent: {
    width: `calc(100% - 24px - ${theme.spacing(1)}px)`, // Remove icon size + spacing
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  chipContainer: {
    width: '130px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  chip: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
    margin: 0,
    maxWidth: '100%',
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
  accordionDetails: {
    paddingTop: 0,
  },
}));

export const DeploymentAccordion = ({
  subscription,
  productVersion,
}: {
  subscription: SubscriptionEntity;
  productVersion: Entity;
}) => {
  const classes = useDeploymentAccordionStyles();
  const [open, setOpen] = React.useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = React.useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('subscription') === stringifyEntityRef(subscription))
      setOpen(true);
  }, [searchParams, subscription]);

  const healthStatusFilter = useDeploymentsStore(
    state => state.healthStatusFilter,
  );
  const syncStatusFilter = useDeploymentsStore(state => state.syncStatusFilter);
  const setProductStatus = useDeploymentsStore(state => state.setProductStatus);

  const statuses = useDeploymentStatus(subscription.metadata.name);

  const productHealthStatus =
    statuses.value?.[subscription.metadata.name].health;
  const productSyncStatus = statuses.value?.[subscription.metadata.name].sync;

  useEffect(() => {
    if (statuses.value) setProductStatus(statuses.value);
  }, [statuses, setProductStatus]);

  const onChange = (_: React.ChangeEvent<{}>, expanded: boolean) => {
    setOpen(expanded);
  };

  const shouldBeDisplayed = useMemo(
    () =>
      (!productHealthStatus && !productSyncStatus) ||
      (healthStatusFilter.includes(productHealthStatus ?? '') &&
        syncStatusFilter.includes(productSyncStatus ?? '')),
    [
      healthStatusFilter,
      syncStatusFilter,
      productSyncStatus,
      productHealthStatus,
    ],
  );

  const handleInspectionDialogClose = () => {
    setInspectionDialogOpen(false);
  };

  return (
    shouldBeDisplayed && (
      <>
        <Accordion
          className={classes.accordion}
          onChange={onChange}
          expanded={open}
        >
          <AccordionSummary classes={{ content: classes.accordionSummary }}>
            {open ? (
              <ExpandLessIcon
                className={classes.expandIcon}
                data-testid="icon-opened"
              />
            ) : (
              <ExpandMoreIcon
                className={classes.expandIcon}
                data-testid="icon-closed"
              />
            )}
            <div className={classes.accordionSummaryContent}>
              <DeploymentAccordionTitle
                title={
                  subscription.metadata.title ?? subscription.metadata.name
                }
                subtitle={
                  subscription.metadata.annotations?.[
                    SUBSCRIPTION_TYPE_ANNOTATION
                  ]
                }
              />
              <DeploymentAccordionStatus
                status={statuses.value?.[subscription.metadata.name]}
              />
              <div className={classes.chipContainer}>
                <Chip
                  label={
                    subscription.metadata.annotations?.[
                      SUBSCRIPTION_PROJECT_ANNOTATION
                    ]
                  }
                  size="small"
                  className={classes.chip}
                  variant="outlined"
                />
              </div>
              <DeploymentAccordionGitInfo
                branchOrTag={
                  productVersion?.metadata.annotations?.[
                    ENTITY_VERSION_REF_NAME_ANNOTATION
                  ]
                }
                commitId={
                  productVersion?.metadata.annotations?.[
                    ENTITY_VERSION_COMMIT_ID_ANNOTATION
                  ]
                }
                commitMessage={
                  productVersion?.metadata.annotations?.[
                    ENTITY_VERSION_COMMIT_MESSAGE_ANNOTATION
                  ]
                }
              />
              <DeploymentAccordionOwner subscription={subscription} />
              <DeploymentAccordionActions
                subscription={subscription}
                onInspectEntity={() => setInspectionDialogOpen(true)}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <DeploymentAccordionContent
              subscription={subscription}
              productVersion={productVersion}
              statuses={statuses.value}
            />
          </AccordionDetails>
        </Accordion>
        {subscription && (
          <InspectEntityDialog
            open={inspectionDialogOpen}
            entity={subscription}
            onClose={handleInspectionDialogClose}
          />
        )}
      </>
    )
  );
};
