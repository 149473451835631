import { Grid } from '@material-ui/core';
import { PackageDiscoveryCard } from './PackageDiscoveryCard';
import React from 'react';
import { Entity } from '@backstage/catalog-model';

interface GridViewProps {
  chunk: Entity[];
}

export const GridView = ({ chunk }: GridViewProps) => (
  <Grid container spacing={2} data-testid="grid-view">
    {chunk.map(packageEntity => (
      <PackageDiscoveryCard
        packageEntity={packageEntity}
        key={`${packageEntity.metadata.namespace}-${packageEntity.metadata.name}`}
      />
    ))}
  </Grid>
);
