import { Entity } from '@backstage/catalog-model';
import React, { useMemo } from 'react';
import { Alert, Skeleton } from '@material-ui/lab';
import { Card, makeStyles } from '@material-ui/core';
import { ProductVersionsListItem } from './ProductVersionsListItem';
import { useDelayedLoading } from '../../../../../../../hooks/useDelayedLoading';
import { useProductVersions } from '../useProductVersions';
import { useThemeMode } from '../../../../../../../utils/useThemeMode';
import noProductLight from '../../../../../../../assets/empty-states/no-product-light.svg';
import noProductDark from '../../../../../../../assets/empty-states/no-product-dark.svg';
import { EmptyState } from '@internal/backstage-plugin-adeo-core-components-react';
import { GithubIcon } from 'backstage-plugin-icons-react';
import { ActionButton } from './ProductRefEntityOverview';
import { LinkButton } from '@backstage/core-components';
import semver from 'semver';

type ProductVersionsListProps = {
  entity: Entity;
};

const useProductVersionsSkeletonItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

const compareVersions = (a: string, b: string) => {
  return semver.rcompare(a, b);
};

const isSemver = (version: string) => semver.valid(version);

const groupByType = (entities: any[]) => {
  const grouped: { [key: string]: any[] } = {};
  const order: string[] = [];

  entities.forEach(entity => {
    const version =
      entity.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '';
    const [type] = version.split('/');

    if (!grouped[type]) {
      grouped[type] = [];
      order.push(type);
    }
    grouped[type].push(entity);
  });

  return { grouped, order };
};

const sortEntities = (entities: any[]) => {
  const semverEntities = entities.filter(entity =>
    isSemver(
      entity.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '',
    ),
  );

  const branchEntities = entities.filter(
    entity =>
      !isSemver(
        entity.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '',
      ),
  );

  semverEntities.sort((a, b) => {
    const aVersion =
      a.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '';
    const bVersion =
      b.metadata.annotations?.['aap.adeo.cloud/version-ref-name'] || '';
    return compareVersions(aVersion, bVersion);
  });

  const { grouped, order } = groupByType(branchEntities);
  const sortedBranchEntities = order.flatMap(type => grouped[type]);

  return [...semverEntities, ...sortedBranchEntities];
};

export const ProductVersionsItemSkeleton = () => {
  const classes = useProductVersionsSkeletonItemStyles();
  return (
    <Card className={classes.root}>
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={150} height={50} />
      <Skeleton variant="rect" height={140} />
    </Card>
  );
};

const useProductVersionsListStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export const ProductVersionsList = (props: ProductVersionsListProps) => {
  const { entity } = props;
  const classes = useProductVersionsListStyles();
  const { entities, loading, error } = useProductVersions(entity);

  const theme = useThemeMode();

  const sortedEntities = useMemo(() => {
    if (!entities) return [];
    return sortEntities(entities);
  }, [entities]);

  const delayedLoading = useDelayedLoading(loading);

  if (delayedLoading) {
    return (
      <div className={classes.root}>
        <ProductVersionsItemSkeleton />
        <ProductVersionsItemSkeleton />
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (sortedEntities?.length === 0) {
    if (entity.spec?.lifecycle === 'declared') {
      return (
        <EmptyState
          title="No product versions found"
          description="This product is declared but not yet initialized. To initialize the product, click the button below."
          illustration={
            <img
              src={theme === 'light' ? noProductLight : noProductDark}
              alt="No product versions found"
            />
          }
          action={<ActionButton entity={entity} />}
        />
      );
    }
    const illustration = (
      <img
        src={theme === 'light' ? noProductLight : noProductDark}
        alt="No product versions found"
      />
    );

    const sourceLocation =
      entity.metadata?.annotations?.['backstage.io/source-location']?.replace(
        'url:',
        '',
      ) ?? undefined;

    return (
      <EmptyState
        title="No product versions found"
        description="This product currently has no product versions. To create a new version, navigate to the Product Definition Repository and update the product definition. Once you've commit the changes, the new product version will appear here."
        illustration={illustration}
        action={
          <LinkButton
            variant="contained"
            color="primary"
            startIcon={<GithubIcon />}
            to={sourceLocation ?? '#'}
          >
            product definition
          </LinkButton>
        }
      />
    );
  }

  return (
    <div className={classes.root}>
      {sortedEntities?.map(productVersion => (
        <ProductVersionsListItem
          key={productVersion.metadata.uid}
          entity={productVersion}
        />
      ))}
    </div>
  );
};

ProductVersionsList.displayName = 'ProductVersionsList';
