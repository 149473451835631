import {
  IconButton,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';
import BugReportIcon from '@material-ui/icons/BugReport';
import React, { useState } from 'react';
import { SubscriptionEntity } from '../../../../../../../../types/SubscriptionEntity';
import { OpenInNewIcon } from 'backstage-plugin-icons-react';
import { useNavigate } from 'react-router';
import { useEntityRoute } from '../../../../../../../../hooks/useEntityRoute';

export type DeploymentAccordionActionsProps = {
  subscription: SubscriptionEntity;
  onInspectEntity: () => void;
};

export const DeploymentAccordionActions = (
  props: DeploymentAccordionActionsProps,
) => {
  const { subscription, onInspectEntity } = props;

  const navigate = useNavigate();
  const subscriptionRoute = useEntityRoute(subscription);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setPopoverOpen(!popoverOpen);
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopoverOpen(false);
  };

  const handleInspectEntity = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    onInspectEntity();
    handleMenuClose(event);
  };

  const goToSubscription = (event: React.MouseEvent<HTMLElement>) => {
    if (subscriptionRoute) {
      navigate(subscriptionRoute);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div>
      <IconButton
        onClick={handleMenuClick}
        aria-label="deployment-actions-menu"
        aria-controls="deployment-actions-menu"
        aria-expanded={popoverOpen}
        aria-haspopup="true"
        ref={buttonRef}
      >
        <MoreVert />
      </IconButton>

      <Popover
        open={popoverOpen}
        onClose={handleMenuClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-labelledby="deployments-long-menu"
      >
        <MenuList autoFocusItem={popoverOpen}>
          <MenuItem onClick={handleInspectEntity}>
            <ListItemIcon>
              <BugReportIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Inspect subscription entity" />
          </MenuItem>
          <MenuItem onClick={goToSubscription}>
            <ListItemIcon>
              <OpenInNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Go to subscription" />
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
};
