/* eslint-disable no-console */
import { type ReviewStepProps } from '@backstage/plugin-scaffolder-react';
import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Content, StructuredMetadataTable } from '@backstage/core-components';
import { UiSchema } from '@rjsf/utils';
import { JsonObject } from '@backstage/types';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

const isPackageFieldFromSelectFieldFromApi = (uiSchema: UiSchema) =>
  uiSchema['ui:field'] === 'SelectFieldFromApi' && uiSchema.name === 'package';

const isGroupFieldFromMyGroupsPicker = (uiSchema: UiSchema) =>
  uiSchema['ui:field'] === 'MyGroupsPicker';

export async function getReviewData(
  catalogApi: any,
  formData: Record<string, any>,
  uiSchemas: UiSchema[],
) {
  const reviewData: Record<string, any> = {};
  const orderedReviewProperties = new Set(
    uiSchemas.map(us => us.name).concat(Object.getOwnPropertyNames(formData)),
  );

  for (const key of orderedReviewProperties) {
    const uiSchema = uiSchemas.find(us => us.name === key);

    if (!uiSchema) {
      reviewData[key] = formData[key];
      continue;
    }

    if (uiSchema['ui:widget'] === 'password') {
      reviewData[key] = '******';
      continue;
    }

    if (!uiSchema['ui:backstage'] || !uiSchema['ui:backstage'].review) {
      if (isPackageFieldFromSelectFieldFromApi(uiSchema)) {
        // TODO: we only support default namespace for now
        const packageFromCatalog = await catalogApi.getEntityByRef({
          kind: 'Component',
          namespace: 'default',
          name: formData[key],
        });
        reviewData[key] =
          `${packageFromCatalog.metadata.title} (${packageFromCatalog.metadata.name})`;
      } else if (isGroupFieldFromMyGroupsPicker(uiSchema)) {
        // TODO: we only support default namespace for now
        const groupName = formData[key].replace('group:default/', '');
        const groupFromCatalog = await catalogApi.getEntityByRef({
          kind: 'Group',
          namespace: 'default',
          name: groupName,
        });
        reviewData[key] = groupFromCatalog.metadata.name;
      } else {
        reviewData[key] = formData[key];
      }

      continue;
    }

    const review = uiSchema['ui:backstage'].review as JsonObject;
    if (review.mask) {
      reviewData[key] = review.mask;
      continue;
    }

    if (!review.show) {
      continue;
    }

    reviewData[key] = formData[key];
  }

  return reviewData;
}

export function getUiSchemasFromSteps(
  steps: {
    schema: JsonObject;
  }[],
): UiSchema[] {
  const uiSchemas: Array<UiSchema> = [];
  steps.forEach(step => {
    const schemaProps = step.schema.properties as JsonObject;
    for (const key in schemaProps) {
      if (schemaProps.hasOwnProperty(key)) {
        const uiSchema = schemaProps[key] as UiSchema;
        uiSchema.name = key;
        uiSchemas.push(uiSchema);
      }
    }
  });
  return uiSchemas;
}

/**
 * Source: https://github.com/backstage/backstage/blob/fadd3ca42af8840fd308c033eeab8fccda8ed63c/plugins/scaffolder/src/legacy/MultistepJsonForm/ReviewStep.tsx
 * The component displaying the Last Step in scaffolder template form.
 * Which represents the summary of the input provided by the end user.
 */
export const ReviewStepComponent = (props: ReviewStepProps) => {
  const { disableButtons, formData, handleBack, handleCreate, steps } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [reviewData, setReviewData] = useState({});
  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const loadReviewData = async () => {
      setIsLoading(true);
      const reviewDataLoaded = await getReviewData(
        catalogApi,
        formData,
        getUiSchemasFromSteps(
          steps.map(({ mergedSchema }: any) => ({ schema: mergedSchema })),
        ),
      );
      setReviewData(reviewDataLoaded);
      setIsLoading(false);
    };

    // Call the function
    loadReviewData();
  }, [catalogApi, formData, steps]);

  return (
    <Content>
      <Paper square elevation={0}>
        <Typography variant="h6">Review and create</Typography>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              minHeight: '10rem',
            }}
          >
            <CircularProgress size="5rem" />
          </Box>
        ) : (
          <StructuredMetadataTable dense metadata={reviewData} />
        )}
        <Box mb={4} />
        <Button onClick={handleBack} disabled={disableButtons}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          disabled={disableButtons}
        >
          Create
        </Button>
      </Paper>
    </Content>
  );
};
