import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

export interface DeploymentStatus {
  health: string;
  sync: string;
}

export const useDeploymentStatus = (subscriptionId: string) => {
  const configApi = useApi(configApiRef);
  const { fetch } = useApi(fetchApiRef);

  return useAsync<() => Promise<Record<string, DeploymentStatus>>>(async () => {
    if (!subscriptionId) return {};

    const backendUrl = configApi.getString('backend.baseUrl');
    const response = await fetch(
      `${backendUrl}/api/aap-argocd/subscription/${subscriptionId}/status`,
    );

    if (!response.ok) {
      throw new Error(
        `Failed to fetch status from Argo (${response.status} ${response.statusText})`,
      );
    }

    return response.json();
  }, [configApi, subscriptionId]);
};
