import { useCallback, useEffect } from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

const sendPianoEvent = (eventName: string, page: string, env: string) => {
  window.pa?.sendEvent(eventName, {
    page: page,
    site_env: env,
  });
};

export const usePianoSendEvent = (eventName: string, page: string) => {
  const config = useApi(configApiRef);
  useEffect(() => {
    sendPianoEvent(
      eventName,
      page,
      config.getOptionalString('app.datadogRum.env') ?? 'local',
    );
  }, [config, eventName, page]);
};

export const usePiano = () => {
  const config = useApi(configApiRef);
  const sendEvent = useCallback(
    (eventName: string, page: string) => {
      sendPianoEvent(
        eventName,
        page,
        config.getOptionalString('app.datadogRum.env') ?? 'local',
      );
    },
    [config],
  );
  return { sendEvent };
};
