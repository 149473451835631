import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ResourceSecrets } from '../common/secrets/ResourceSecrets/ResourceSecrets';
import { Section } from '../common/Section';
import { OpenInNewIcon } from 'backstage-plugin-icons-react';

const usePostgresqlDeploymentContentStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  linkLabel: {
    color: theme.palette.link,
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  linkIcon: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

export const PostgresqlDeploymentContent = () => {
  const classes = usePostgresqlDeploymentContentStyles();

  return (
    <div className={classes.root} data-testid="postgre-drawer-content">
      <ResourceSecrets />
      <Section title="Resources">
        <a
          href="https://docs.packaging.adeo.cloud/latest/developers/howto/resources/postgresql/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.linkLabel}
        >
          <Typography variant="body1">
            AAP PostgreSQL resource documentation
          </Typography>{' '}
          <OpenInNewIcon className={classes.linkIcon} />
        </a>
      </Section>
    </div>
  );
};
