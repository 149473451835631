import { SectionContent, TabContent } from '../../SectionContent';
import { ComponentSecretsContentHeader } from './ComponentSecretsContentHeader';
import React from 'react';
import { SectionContentWithHeader } from '../SectionContentWithHeader';

interface SecretsContentRendererProps {
  currentTab: string;
  secrets: TabContent<string[] | Record<string, string[]>>;
}

export const ComponentSecretsContentRenderer = ({
  currentTab,
  secrets,
}: SecretsContentRendererProps) => {
  if (currentTab === 'common') {
    return (
      <SectionContent
        currentTab={currentTab}
        showLineNumber
        tabsContent={{ common: secrets.common as { data: string[] } }}
        emptyDataMessage="No common secrets to display for this component"
      />
    );
  } else if (currentTab === 'resources') {
    return (
      <SectionContentWithHeader
        secretData={secrets.resources.data as Record<string, string[]>}
        emptySecretsMessage="No secrets to display for this resource in this component"
        SecretsContentHeaderComponent={ComponentSecretsContentHeader}
      />
    );
  }
  return null;
};
