import React, { forwardRef, useState } from 'react';
import { Link } from '@backstage/core-components';
import { IconComponent } from '@backstage/core-plugin-api';
import { makeStyles, Typography } from '@material-ui/core';
import { useBreadcrumbsContext } from './Breadcrumbs';

export type BreadcrumbsItemsProps = {
  label: string;
} & (
  | {
      isCompact: true;
      isLast?: false;
      to?: string;
      Icon: IconComponent;
    }
  | ({
      isCompact?: false;
      Icon?: IconComponent;
    } & (
      | {
          to: string;
          isLast?: false;
        }
      | {
          to?: never;
          isLast: true;
        }
    ))
) &
  React.HTMLAttributes<HTMLDivElement>;

const useBreadcrumbItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    transition: 'all 0.3s ease-in-out',
    maxWidth: '300px',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  lastItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.primary,
  },

  typography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },

  linkCompact: {
    maxWidth: '24px',
  },
}));

export const BreadcrumbsItems = forwardRef<
  HTMLDivElement,
  BreadcrumbsItemsProps
>((props, ref) => {
  const {
    Icon,
    label,
    to,
    isLast = false,
    isCompact = false,
    className,
    ...rest
  } = props;

  const classes = useBreadcrumbItemStyles();
  const rootClassName = [classes.root, className].join(' ');
  const { isExpandable } = useBreadcrumbsContext();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (!isExpandable) return;
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isExpandable) return;
    setIsHovered(false);
  };

  const renderContent = () => {
    if (isLast) {
      return (
        <div className={classes.lastItem}>
          {Icon && <Icon fontSize="small" />}
          <Typography variant="body1" className={classes.typography}>
            {label}
          </Typography>
        </div>
      );
    }

    if (isCompact) {
      return (
        <Link
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={`${classes.link} ${isHovered ? '' : classes.linkCompact}`}
          to={to as string}
        >
          {Icon && <Icon fontSize="small" />}
          <Typography variant="body1" className={classes.typography}>
            {label}
          </Typography>
        </Link>
      );
    }

    return (
      <Link className={classes.link} to={to as string}>
        {Icon && <Icon fontSize="small" />}
        <Typography variant="body1" className={classes.typography}>
          {label}
        </Typography>
      </Link>
    );
  };

  return (
    <div ref={ref} className={rootClassName} {...rest}>
      {renderContent()}
    </div>
  );
});

BreadcrumbsItems.displayName = 'BreadcrumbsItems';
