import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Query, useQuery, UseQueryResult } from '@tanstack/react-query';

type useCachedRelatedEntitiesType = (
  entity?: Entity,
  relationFilter?: {
    type?: string;
    kind?: string;
  },
  queryOptions?: {
    refetchOnMount:
      | boolean
      | ((
          query: Query<Entity[], unknown, Entity[], (string | undefined)[]>,
        ) => boolean);
  },
) => UseQueryResult<Entity[], unknown>;

export const useCachedRelatedEntities: useCachedRelatedEntitiesType = (
  entity,
  relationFilter,
  queryOptions,
) => {
  const catalogApi = useApi(catalogApiRef);
  const relations =
    entity?.relations?.map(relation => relation.targetRef) ?? [];

  const kindFilter = { kind: relationFilter?.kind };
  const typeFilter = { 'spec.type': relationFilter?.type };

  return useQuery({
    queryKey: [
      'relatedEntities',
      entity?.metadata.namespace,
      entity?.metadata.name,
    ],
    queryFn: async () => {
      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: relations,
        filter: {
          ...(relationFilter?.kind && kindFilter),
          ...(relationFilter?.type && typeFilter),
        },
      });

      return items.filter((item): item is Entity => Boolean(item));
    },
    initialData: [],
    ...queryOptions,
  });
};
