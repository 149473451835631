import {
  SectionContent,
  SectionContentList,
  SectionContentListItem,
} from '../SectionContent';
import React, { useCallback, useMemo, useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import { parseComponentOrResourceName } from '../manifest-utils';
import { Alert, Skeleton } from '@material-ui/lab';
import { ComponentSecretsContentHeaderProps } from './ComponentSecrets/ComponentSecretsContentHeader';
import { ResourceSecretsContentHeaderProps } from './ResourceSecrets/ResourceSecretsContentHeader';
import { makeStyles } from '@material-ui/core';
import { useDelayedLoading } from '../../../../../../../../../hooks/useDelayedLoading';

const useSectionContentWithHeaderSkeletonStyles = makeStyles(theme => ({
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonLine: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
}));

const SectionContentWithHeaderSkeleton = () => {
  const classes = useSectionContentWithHeaderSkeletonStyles();

  return (
    <div className={classes.skeletonContainer}>
      {Array(3)
        .fill(null)
        .map(() => (
          <div className={classes.skeletonLine}>
            <Skeleton width={15} height={25} />
            <Skeleton width={400} height={25} />
          </div>
        ))}
    </div>
  );
};

interface SectionContentWithHeaderProps {
  secretData: Record<string, string[]>;
  emptySecretsMessage: string;
  SecretsContentHeaderComponent: React.FC<
    ComponentSecretsContentHeaderProps | ResourceSecretsContentHeaderProps
  >;
}

export const SectionContentWithHeader = ({
  secretData,
  emptySecretsMessage,
  SecretsContentHeaderComponent,
}: SectionContentWithHeaderProps) => {
  const [secretsToDisplay, setSecretsToDisplay] = useState<string[]>([]);
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onLoadingChange = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const loading = useDelayedLoading(isLoading);

  // Memoize to avoid triggering useEffect that have a dependency on this function
  const onEntityChange = useCallback(
    (entity: Entity) => {
      const parsedEntityName = parseComponentOrResourceName(entity);

      if (!parsedEntityName) {
        setError(
          new Error(`Failed to parse entity name for ${entity.metadata.title}`),
        );
        return;
      }

      setError(undefined);
      setSecretsToDisplay(secretData[parsedEntityName]);
    },
    [secretData],
  );

  const defaultEntityName = useMemo(
    () =>
      Object.keys(secretData).find(
        entityName => secretData[entityName].length > 0,
      ),
    [secretData],
  );

  const renderContent = () => {
    if (error) {
      return <Alert severity="warning">{error.message}</Alert>;
    }

    if (secretsToDisplay?.length) {
      return secretsToDisplay?.map((secret, index) => (
        <SectionContentListItem
          showLineNumber
          value={secret}
          index={index}
          key={index}
        />
      ));
    }

    return <Alert severity="info">{emptySecretsMessage}</Alert>;
  };

  return (
    <SectionContent>
      <SecretsContentHeaderComponent
        onChange={onEntityChange}
        defaultResourceName={defaultEntityName}
        onLoadingChange={onLoadingChange}
        hasSecrets={secretsToDisplay?.length > 0}
      />
      {loading ? (
        <SectionContentWithHeaderSkeleton />
      ) : (
        <SectionContentList showLineNumber>
          {renderContent()}
        </SectionContentList>
      )}
    </SectionContent>
  );
};
