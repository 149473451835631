import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';

export const useThemeMode = () => {
  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = appThemeApi.getActiveThemeId();

  if (activeThemeId) {
    return activeThemeId;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};
