import React, { useEffect } from 'react';

export const Hotjar = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const scriptElement = document.createElement('script');
      scriptElement.textContent = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3894302,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

      const head = document.head || document.getElementsByTagName('head')[0];

      head.appendChild(scriptElement);

      return () => {
        head.removeChild(scriptElement);
      };
    }
    return () => {};
  }, []);
  return <></>;
};
