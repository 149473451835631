import { Entity } from '@backstage/catalog-model';
import { useEntityRoute } from '../../../hooks/useEntityRoute';
import React from 'react';
import { EntityPresentationMetricsItem } from '@internal/backstage-plugin-adeo-core-components-react';
import { Inventory2Icon } from 'backstage-plugin-icons-react';

export const PackageCellRenderer = ({
  packageEntity,
}: {
  packageEntity: Entity;
}) => {
  const packageRoute = useEntityRoute(packageEntity);

  return (
    <EntityPresentationMetricsItem
      label=""
      value={packageEntity.metadata.title ?? packageEntity.metadata.name}
      Icon={Inventory2Icon}
      link={packageRoute}
      hideLabel
      overflowEllipsis
    />
  );
};
