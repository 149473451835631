import React from 'react';
import { RELATION_DEPENDENCY_OF } from '@backstage/catalog-model';
import {
  useEntity,
  useRelatedEntities,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';

import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import {
  HomeIcon,
  Package2Icon,
  Inventory2Icon,
} from 'backstage-plugin-icons-react';
import { useRouteRef } from '@backstage/core-plugin-api';
import { usePianoSendEvent } from '../../../../hooks/usePianoSendEvent';

const ProductRefEntityBreadcrumb = () => {
  usePianoSendEvent('page.display', 'product');

  const { entity } = useEntity();
  const generateEntityRoute = useRouteRef(entityRouteRef);
  const { entities, loading, error } = useRelatedEntities(entity, {
    kind: 'Component',
    type: RELATION_DEPENDENCY_OF,
  });

  const packageEntity = entities?.find(ent => ent.spec?.type === 'package');

  const packageRoute = generateEntityRoute({
    kind: 'Component',
    namespace: packageEntity?.metadata.namespace ?? '',
    name: packageEntity?.metadata.name ?? '',
  });

  if (loading || error) {
    return null;
  }

  return (
    <Breadcrumbs>
      <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
      <BreadcrumbsItems
        label={packageEntity?.metadata.title ?? ''}
        to={packageRoute}
        Icon={Inventory2Icon}
      />
      <BreadcrumbsItems
        label={entity.metadata.title ?? ''}
        Icon={Package2Icon}
        isLast
      />
    </Breadcrumbs>
  );
};

ProductRefEntityBreadcrumb.displayName = 'ProductRefEntityBreadcrumb';

export { ProductRefEntityBreadcrumb };
