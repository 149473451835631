import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { EntityLink } from '@backstage/catalog-model';
import { FullHeightCard } from '../../../../../utils/FullHeightCard';
import Launch from '@material-ui/icons/Launch';
import { Link } from '@backstage/core-components';

interface SubscriptionLinksProps {
  links: EntityLink[];
}

const useStyles = makeStyles({
  svgIcon: {
    display: 'inline-block',
    '& svg': {
      display: 'inline-block',
      fontSize: 'inherit',
      verticalAlign: 'baseline',
    },
  },
});

const RoutesList = ({ routes }: { routes: EntityLink[] }) => {
  const classes = useStyles();

  if (routes.length === 0) {
    return (
      <Grid item sm={12}>
        <Typography variant="body2">No routes</Typography>
      </Grid>
    );
  }
  return (
    <>
      {routes.map(route => (
        <Grid item key={route.url} sm={12}>
          <Box display="flex">
            <Box mr={1} className={classes.svgIcon}>
              <Typography component="div">
                <Launch />
              </Typography>
            </Box>
            <Box flexGrow="1">
              <Link to={route.url} target="_blank" rel="noopener">
                {route.title || route.url}
              </Link>
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
};

export const SubscriptionRoutes = ({ links }: SubscriptionLinksProps) => {
  const routes = links.filter(
    link => link.type === 'group' || link.type === 'global',
  );

  return (
    <FullHeightCard>
      <CardHeader title="Routes" />
      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          <RoutesList routes={routes} />
        </Grid>
      </CardContent>
    </FullHeightCard>
  );
};
