import React from 'react';
import { DataGrid, type DataGridProps, gridClasses } from '@mui/x-data-grid';

export const Table = (props: DataGridProps) => {
  const { columns, rows, ...rest } = props;

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      density="compact"
      hideFooter
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
      }}
      {...rest}
    />
  );
};

Table.displayName = 'Table';
