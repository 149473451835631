import { TabContent } from '../SectionContent';
import { useDeploymentsStore } from '../../../store/deployments-store';
import { getComponentOrResourceAppliedStatus } from '../manifest-utils';
import {
  ApiAppliedStatus,
  FrontendAppliedStatus,
} from '../../../../../../../../../types/applied-status/AppliedStatus';

export const useComponentLinks = ():
  | { error: Error }
  | { data: TabContent } => {
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);

  if (!entity || !subscription || !productVersion)
    return {
      error: new Error(
        'Could not find component links because either component, subscription or productVersion is missing',
      ),
    };

  const componentAppliedStatusOrError = getComponentOrResourceAppliedStatus<
    ApiAppliedStatus | FrontendAppliedStatus
  >(entity, subscription, productVersion);

  if ('error' in componentAppliedStatusOrError)
    return componentAppliedStatusOrError;

  const links = componentAppliedStatusOrError?.links;

  if (!links) return { error: new Error('No component links found') };

  const formattedLinks = links.reduce<TabContent>(
    (acc, link) => {
      return {
        ...acc,
        [link.type]: {
          data: [...acc[link.type].data, link.url],
        },
      };
    },
    {
      local: { data: [] },
      group: { data: [] },
      global: { data: [] },
    },
  );

  return { data: formattedLinks };
};
