import { OpenInNewIcon } from 'backstage-plugin-icons-react';
import { type MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getLinkMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (!entity.metadata.links || entity.metadata.links.length === 0)
    return { metrics: [] };
  const metrics = entity.metadata.links.map(link => {
    return createMetrics(
      link.title?.toString() ?? link.url,
      'Link',
      OpenInNewIcon,
      link.url,
    );
  });

  return { metrics };
};
