import React from 'react';
import { type GroupEntity, type UserEntity } from '@backstage/catalog-model';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { RELATION_HAS_MEMBER } from '@backstage/catalog-model';
import { EntityPresentationMembers } from '@internal/backstage-plugin-adeo-core-components-react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useDelayedLoading } from '../../../../../../../hooks/useDelayedLoading';

type ProductRefEntityUsersProps = {
  ownerGroup: GroupEntity;
  loading?: boolean;
};

const createMember = (
  entity: UserEntity,
  roleByIds: Record<string, string>,
) => ({
  displayName: entity.spec?.profile?.displayName?.toString() ?? '',
  email: entity.spec?.profile?.email?.toString() ?? '',
  job: roleByIds[entity.metadata.name] ?? '',
});

const useProductRefEntityUsersStyles = makeStyles(theme => ({
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const ProductRefEntityUsers = (props: ProductRefEntityUsersProps) => {
  const { ownerGroup, loading = false } = props;
  const classes = useProductRefEntityUsersStyles();

  const mainContactsIds =
    ownerGroup?.metadata?.annotations?.['aap.adeo.cloud/main-contacts']?.split(
      ',',
    ) ?? [];

  const roleByIds =
    ownerGroup?.metadata?.annotations?.['aap.adeo.cloud/team-roles']
      ?.split(',')
      .reduce((acc: Record<string, string>, role: string) => {
        const [id, roleName] = role.split(';');
        acc[id] = roleName;
        return acc;
      }, {}) ?? {};

  const {
    loading: loadingUsers,
    entities,
    error,
  } = useRelatedEntities(ownerGroup, {
    kind: 'user',
    type: RELATION_HAS_MEMBER,
  }) as {
    loading: boolean;
    entities: UserEntity[];
    error?: Error;
  };

  const delayedLoading = useDelayedLoading(loadingUsers);

  const mainMembers = entities
    ?.filter(entity => mainContactsIds.includes(entity.metadata.name))
    .map(entity => createMember(entity, roleByIds));

  const otherMembers = entities
    ?.filter(entity => !mainContactsIds.includes(entity.metadata.name))
    .map(entity => createMember(entity, roleByIds));

  if (error) {
    return (
      <Alert severity="error" className={classes.errorContainer}>
        {error.message}
      </Alert>
    );
  }

  return (
    <EntityPresentationMembers
      mainMembers={mainMembers ?? []}
      otherMembers={otherMembers ?? []}
      loading={loadingUsers || loading || delayedLoading}
    />
  );
};
