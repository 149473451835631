import React, { forwardRef, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';

interface SectionProps {
  title: string;
}

const useSectionStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    display: 'block',
  },
}));

export const Section = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SectionProps>
>(({ children, title }, ref) => {
  const classes = useSectionStyles();

  return (
    <div className={classes.root} ref={ref}>
      <span className={classes.sectionTitle}>{title}</span>
      {children}
    </div>
  );
});
