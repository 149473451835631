import { useQuery } from '@tanstack/react-query';
import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const usePackages = () => {
  const catalogApi = useApi(catalogApiRef);

  return useQuery<Entity[]>({
    queryKey: ['package-discovery'],
    queryFn: async () => {
      const { items } = await catalogApi.getEntities({
        filter: { kind: 'Component', 'spec.type': 'package' },
        order: { field: 'metadata.title', order: 'asc' },
      });

      return items;
    },
    initialData: [],
  });
};
