import { Entity } from '@backstage/catalog-model';
import {
  Content,
  Link,
  Page,
  Progress,
  RoutedTabs,
  WarningPanel,
} from '@backstage/core-components';
import {
  attachComponentData,
  useElementFilter,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  entityRouteRef,
  InspectEntityDialog,
  UnregisterEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import { TabProps } from '@material-ui/core/Tab';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import EntityLayoutHeader from './EntityLayoutHeader';

/** @public */
export type EntityLayoutRouteProps = {
  path: string;
  title: string;
  children: JSX.Element;
  if?: (entity: Entity) => boolean;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

const dataKey = 'plugin.catalog.entityLayoutRoute';

const Route: (props: EntityLayoutRouteProps) => null = () => null;
attachComponentData(Route, dataKey, true);
attachComponentData(Route, 'core.gatherMountPoints', true);

/** @public */
export interface EntityLayoutProps {
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
}

export const EntityLayout = (props: EntityLayoutProps) => {
  const { children, NotFoundComponent } = props;
  const { kind } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const location = useLocation();
  const routes = useElementFilter(
    children,
    elements =>
      elements
        .selectByComponentData({
          key: dataKey,
          withStrictError:
            'Child of EntityLayout must be an EntityLayout.Route',
        })
        .getElements<EntityLayoutRouteProps>() // all nodes, element data, maintain structure or not?
        .flatMap(({ props: elementProps }) => {
          if (!entity) {
            return [];
          }

          if (elementProps.if && !elementProps.if(entity)) {
            return [];
          }

          return [
            {
              path: elementProps.path,
              title: elementProps.title,
              children: elementProps.children,
              tabProps: elementProps.tabProps,
            },
          ];
        }),
    [entity],
  );

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
  };

  useEffect(() => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <EntityLayoutHeader />

      {loading && <Progress />}

      {entity && <RoutedTabs routes={routes} />}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent ?? (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                kind, namespace, and name
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity!}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

EntityLayout.Route = Route;
