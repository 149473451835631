import { Entity } from '@backstage/catalog-model';
import { useNavigate } from 'react-router';
import { useEntityRoute } from '../../../hooks/useEntityRoute';
import { GridActionsCellItem } from '@mui/x-data-grid';
import React from 'react';
import { ArrowRightAltIcon } from 'backstage-plugin-icons-react';

export const PackageGridActionsCellItem = ({ entity }: { entity: Entity }) => {
  const navigate = useNavigate();
  const packageRoute = useEntityRoute(entity);

  const onClick = () => {
    if (packageRoute) navigate(packageRoute);
  };

  return (
    <GridActionsCellItem
      key="see-details"
      icon={<ArrowRightAltIcon />}
      color="primary"
      label="See details"
      onClick={onClick}
    />
  );
};
