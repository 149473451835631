import { Chip, type ChipProps } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import React, { forwardRef } from 'react';

export type ChipLinkProps = {
  to: string;
  color?: ChipProps['color'];
  variant?: ChipProps['variant'];
  size?: ChipProps['size'];
  icon?: ChipProps['icon'];
  label?: ChipProps['label'];
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const ChipLink = forwardRef<HTMLAnchorElement, ChipLinkProps>(
  (props, ref) => {
    const { color, variant, size, label, to, icon, ...restProps } = props;
    return (
      <Link to={to} ref={ref} {...restProps}>
        <Chip
          component="span"
          clickable
          color={color}
          variant={variant}
          size={size}
          label={label}
          icon={icon}
        />
      </Link>
    );
  },
);
