import { Grid } from '@material-ui/core';
import { EntityWarningContent } from './EntityWarningContent';
import {
  EntityAboutCard,
  EntityHasSubcomponentsCard,
  EntityLinksCard,
} from '@backstage/plugin-catalog';
import React from 'react';

export const OverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {EntityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>

    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
  </Grid>
);
