import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Avatar as MuiAvatar,
  type AvatarProps as MuiAvatarProps,
  Typography,
} from '@material-ui/core';
import { MailIcon } from 'backstage-plugin-icons-react';

import { getHash } from '../../utils/getHash';

/**
 * Colors for the avatar.
 *
 * @private
 */
export const avatarColors = [
  '#00919f',
  '#605f9d',
  '#0b96cc',
  '#46a610',
  '#ea7315',
  '#ea302d',
];

/**
 * Returns a color for the avatar based on the user's name.
 *
 * @param firstName The user's first name.
 * @param lastName The user's last name.
 * @returns A color for the avatar.
 * @private
 */
export const getAvatarColor = (
  displayName: string,
): (typeof avatarColors)[number] => {
  const hash = getHash(displayName);
  const index = hash % avatarColors.length;
  return avatarColors[index];
};

/**
 * User information for the avatar.
 *
 * @public
 */
export type AvatarUser = {
  displayName: string;
  email: string;
  job: string;
  avatarImage?: string;
} & Pick<MuiAvatarProps, 'imgProps'>;

/**
 * Props for {@link Avatar}.
 */
export type AvatarProps = {
  isCompact?: boolean;
  size: 'medium' | 'small';
  className?: string;
  style?: React.CSSProperties;
} & AvatarUser;

const useAvatarStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  userBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  nameAndJob: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0),
  },
  name: {
    fontWeight: 'bold',
  },
  job: {
    color: theme.palette.text.secondary,
  },
  small: {
    fontSize: '16px',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  medium: {
    fontSize: '20px',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

/**
 * Displays an avatar.
 *
 * @remarks
 *
 * This component displays an avatar. If the avatar is not compact, it also displays the user's
 * name, role, and an email button. An image can be provided in the `src` prop. If not, the
 * component will generate an avatar with the user's initials. The color of the avatar is
 * determined by the user's name.
 *
 * @public
 */
export const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const {
    isCompact = false,
    size,
    displayName,
    email,
    job,
    style,
    className,
    avatarImage,
    ...avatarProps
  } = props;
  const classes = useAvatarStyles();

  const avatarColor = getAvatarColor(displayName);

  const initials = displayName.split(' ').map((name: string) => name[0]);

  if (isCompact) {
    return (
      <MuiAvatar
        className={`${size === 'small' ? classes.small : classes.medium} ${className}`}
        alt={displayName}
        style={{ backgroundColor: avatarColor, ...style }}
        src={avatarImage}
        ref={ref}
        {...avatarProps}
      >
        {initials}
      </MuiAvatar>
    );
  }

  return (
    <div className={`${classes.root} ${className}`} ref={ref}>
      <div className={classes.userBox}>
        <MuiAvatar
          className={`${size === 'small' ? classes.small : classes.medium}`}
          alt={displayName}
          style={{ backgroundColor: avatarColor }}
          src={avatarImage}
          {...avatarProps}
        >
          {initials}
        </MuiAvatar>
        <div className={classes.nameAndJob}>
          <Typography
            variant={size === 'small' ? 'body2' : 'body1'}
            className={classes.name}
          >
            {displayName}
          </Typography>
          <Typography
            variant={size === 'small' ? 'body2' : 'body1'}
            className={classes.job}
          >
            {job}
          </Typography>
        </div>
      </div>

      <IconButton
        className={classes.button}
        color="primary"
        aria-label="email"
        size={size}
        href={`mailto:${email}`}
      >
        <MailIcon />
      </IconButton>
    </div>
  );
});

Avatar.displayName = 'Avatar';
