import {
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { classNames } from '../../../../../../../utils/classNames';
import { StatusBadge } from '../StatusBadge';
import FormControl from '@material-ui/core/FormControl';
import React, { useCallback } from 'react';
import { RenderArgoStatusFilterValue } from './RenderArgoStatusFilterValue';
import { useDeploymentsStore } from '../store/deployments-store';

const useHealthStatusStyle = makeStyles(theme => ({
  formControl: {
    flex: 1,
  },
  select: {
    background: theme.palette.background.paper,
  },
  selectWithChip: {
    '& > [class^="MuiSelect-root"]': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export const HealthStatusFilter = () => {
  const classes = useHealthStatusStyle();

  const healthStatuses = useDeploymentsStore(state => state.healthStatus);
  const healthStatusesFilter = useDeploymentsStore(
    state => state.healthStatusFilter,
  );
  const setHealthStatusesFilter = useDeploymentsStore(
    state => state.setHealthStatusesFilter,
  );

  const onStatusesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setHealthStatusesFilter(event.target.value as string[]);
  };

  const renderStatusesValue = useCallback(
    (inputStatuses: unknown) => (
      <RenderArgoStatusFilterValue
        inputStatuses={inputStatuses as string[]}
        statuses={healthStatuses}
        title="Health"
      />
    ),
    [healthStatuses],
  );

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="health-status-label"
        id="health-status-select"
        value={healthStatusesFilter}
        multiple
        displayEmpty
        renderValue={renderStatusesValue}
        onChange={onStatusesChange}
        className={classNames(
          classes.select,
          healthStatusesFilter.length ? classes.selectWithChip : null,
        )}
        MenuProps={{
          getContentAnchorEl: null,
        }}
      >
        {healthStatuses.map(status => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={healthStatusesFilter.includes(status)} />
            <ListItemText
              primary={
                <span>
                  <StatusBadge status={status} /> {status}
                </span>
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
