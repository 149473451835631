import { makeStyles } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { MetricsKey, useMetrics } from '../../../hooks/metrics/useMetrics';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { EntityPresentationMetricsItem } from '@internal/backstage-plugin-adeo-core-components-react';

const useCellRendererStyle = makeStyles(theme => ({
  error: {
    margin: theme.spacing(2),
  },
}));

export const MetricsCellRenderer = ({
  entity,
  metric,
}: {
  entity: Entity;
  metric: MetricsKey;
}) => {
  const classes = useCellRendererStyle();
  const { loading, metrics, error } = useMetrics(entity, [metric]);

  if (error) {
    return (
      <Alert severity="error" className={classes.error}>
        {error.message}
      </Alert>
    );
  }

  return (
    !loading && (
      <EntityPresentationMetricsItem
        {...metrics[0]}
        hideLabel
        overflowEllipsis
      />
    )
  );
};
