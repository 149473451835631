import React, { useState } from 'react';
import {
  InspectEntityDialog,
  UnregisterEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import { EntityContextMenu } from './EntityContextMenu';
import { Header } from '../../header/Header';
import { getBreadcrumb } from './entity-breadcrumbs/getBreadcrumb';

const EntityLayoutHeader = () => {
  const { entity } = useAsyncEntity();
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const Breadcrumb = getBreadcrumb(entity);

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
  };

  return (
    <Header>
      {Breadcrumb && <Breadcrumb />}
      <EntityContextMenu
        onInspectEntity={() => setInspectionDialogOpen(true)}
        onUnregisterEntity={() => setConfirmationDialogOpen(true)}
      />

      {entity && (
        <>
          <UnregisterEntityDialog
            open={confirmationDialogOpen}
            entity={entity}
            onConfirm={cleanUpAfterRemoval}
            onClose={() => setConfirmationDialogOpen(false)}
          />
          <InspectEntityDialog
            open={inspectionDialogOpen}
            entity={entity}
            onClose={() => setInspectionDialogOpen(false)}
          />
        </>
      )}
    </Header>
  );
};

EntityLayoutHeader.displayName = 'EntityLayoutHeader';

export default EntityLayoutHeader;
