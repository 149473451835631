import React from 'react';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { DefaultEntityPage } from './pages/DefaultEntityPage';
import { ComponentPage } from './pages/component/ComponentPage';
import { ApiPage } from './pages/ApiPage';
import { DomainPage } from './pages/DomainPage';
import { SystemPage } from './pages/SystemPage';
import { GroupPage } from './pages/GroupPage';
import { UserPage } from './pages/UserPage';
import { ResourcePage } from './pages/resource/ResourcePage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={ComponentPage} />
    <EntitySwitch.Case if={isKind('api')} children={ApiPage} />
    <EntitySwitch.Case if={isKind('group')} children={GroupPage} />
    <EntitySwitch.Case if={isKind('user')} children={UserPage} />
    <EntitySwitch.Case if={isKind('system')} children={SystemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={DomainPage} />
    <EntitySwitch.Case if={isKind('resource')} children={ResourcePage} />
    <EntitySwitch.Case>{DefaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
