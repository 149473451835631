import { Grid } from '@material-ui/core';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import React from 'react';

export const RelationsContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
  </Grid>
);
