import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { HomeIcon, GroupIcon } from 'backstage-plugin-icons-react';
import { GroupEntity } from '@backstage/catalog-model';
import { usePianoSendEvent } from '../../../../hooks/usePianoSendEvent';

const GroupEntityBreadcrumb = () => {
  const { entity } = useEntity<GroupEntity>();
  usePianoSendEvent('page.display', 'group');

  return (
    <Breadcrumbs>
      <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
      <BreadcrumbsItems
        label={entity.spec.profile?.displayName ?? entity.metadata.name ?? ''}
        Icon={GroupIcon}
        isLast
      />
    </Breadcrumbs>
  );
};

GroupEntityBreadcrumb.displayName = 'GroupEntityBreadcrumb';

export { GroupEntityBreadcrumb };
