import { createContext } from 'react';

/**
 * Type for {@link EntityPresentationContext}.
 *
 * @internal
 */
type EntityPresentationContextType = {
  size: 'small' | 'medium';
};

/**
 * Context for entity presentation.
 *
 * @internal
 */
export const EntityPresentationContext =
  createContext<EntityPresentationContextType>({
    size: 'medium',
  });
