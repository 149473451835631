import { Entity } from '@backstage/catalog-model';
import { Table } from '@internal/backstage-plugin-adeo-core-components-react';
import React, { useCallback, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core';
import { MetricsCellRenderer } from './CellRenderer';
import { PackageGridActionsCellItem } from './PackageGridActionsCellItem';
import { PackageCellRenderer } from './PackageCellRenderer';

interface ListViewProps {
  chunk: Entity[];
}

const useListViewStyles = makeStyles(theme => ({
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px !important`,
  },
  descriptionWrapper: {
    overflow: 'hidden',
  },
  descriptionValue: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export const ListView = ({ chunk }: ListViewProps) => {
  const classes = useListViewStyles();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'title',
        headerName: 'Package',
        flex: 0.7,
        renderCell: params => (
          <PackageCellRenderer packageEntity={params.row.rawEntity} />
        ),
      },
      {
        field: 'owner',
        headerName: 'Owner',
        flex: 0.6,
        renderCell: params => (
          <MetricsCellRenderer entity={params.row.rawEntity} metric="owner" />
        ),
      },
      {
        field: 'products',
        headerName: 'Products',
        width: 100,
        renderCell: params => (
          <MetricsCellRenderer
            entity={params.row.rawEntity}
            metric="products"
          />
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        renderCell: params => (
          <div className={classes.descriptionWrapper}>
            <p className={classes.descriptionValue}>{params.value}</p>
          </div>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.2,
        type: 'actions',
        getActions: params => [
          <PackageGridActionsCellItem entity={params.row.rawEntity} />,
        ],
      },
    ],
    [classes.descriptionValue, classes.descriptionWrapper],
  );

  const rows = useMemo(
    () =>
      chunk?.map(entity => ({
        id: entity.metadata.uid,
        title: entity.metadata.title ?? entity.metadata.name,
        owner: entity.spec?.owner,
        products: 0,
        description: entity.metadata.description,
        rawEntity: entity,
      })) ?? [],
    [chunk],
  );

  const getCellClassName = useCallback(() => classes.tableCell, [classes]);

  return (
    <Table
      columns={columns}
      rows={rows}
      rowHeight={90}
      columnHeaderHeight={90}
      pageSizeOptions={[10, 7]}
      getCellClassName={getCellClassName}
      disableColumnSorting
      disableColumnResize
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      sx={theme => ({
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          background: theme.palette.background.paper,
        },
      })}
      data-testid="list-view"
    />
  );
};
