export const ENTITY_ID_ANNOTATION = 'packaging.adeo.cloud/backstage-entity-id';
export const ENTITY_CALLBACK_URL_ANNOTATION =
  'packaging.adeo.cloud/backstage-callback-url';
export const IS_RELEASE_ANNOTATION = 'aap.adeo.cloud/release';
export const TANGRAM_ID_ANNOTATION = 'packaging.adeo.cloud/product-external-id';
export const SOURCE_CODE_URL_ANNOTATION = 'backstage.io/source-location';
export const GITHUB_REPO_SLUG_ANNOTATION = 'github.com/project-slug';
export const SUBSCRIPTION_TYPE_ANNOTATION = 'aap.adeo.cloud/subscription-type';
export const SUBSCRIPTION_PROJECT_ANNOTATION = 'aap.adeo.cloud/project';
export const ENTITY_VERSION_COMMIT_ID_ANNOTATION = 'aap.adeo.cloud/commit-id';
export const ENTITY_VERSION_COMMIT_MESSAGE_ANNOTATION =
  'aap.adeo.cloud/commit-message';
export const ENTITY_VERSION_REF_NAME_ANNOTATION =
  'aap.adeo.cloud/version-ref-name';
export const ARGO_APP_NAME_ANNOTATION = 'aap.adeo.cloud/application-name';
