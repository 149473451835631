import { TabsSection } from '../../TabsSection';
import React from 'react';
import { Section } from '../../Section';
import { Alert } from '@material-ui/lab';
import { useComponentSecrets } from './useComponentSecrets';
import { ComponentSecretsContentRenderer } from './ComponentSecretsContentRenderer';

export const ComponentSecrets = () => {
  const secretsOrError = useComponentSecrets();

  if ('error' in secretsOrError) {
    return (
      <Section title="Secrets">
        <Alert severity="error">{secretsOrError.error.message}</Alert>
      </Section>
    );
  }

  return (
    <Section title="Secrets">
      <TabsSection
        tabsTitle={[
          { label: 'Common', value: 'common' },
          { label: 'Resources', value: 'resources' },
        ]}
        renderContent={currentTab => (
          <ComponentSecretsContentRenderer
            currentTab={currentTab}
            secrets={secretsOrError.data}
          />
        )}
      />
    </Section>
  );
};
