import { ComponentManifest } from '../../../../../../../../../../types/applied-product-manifest/AppliedProductManifest';
import { ResourceTypes } from '../../../../../../../../../../types/applied-product-manifest/CommonComponentManifest';
import { TabContent } from '../../SectionContent';
import { useDeploymentsStore } from '../../../../store/deployments-store';
import { getComponentOrResourceAppliedManifest } from '../../manifest-utils';

const getCommonSecrets = (componentManifest: ComponentManifest) => {
  const commonSecrets = componentManifest?.secrets?.env?.fromCommonKeys;

  return commonSecrets?.map(env => env.name) ?? [];
};

const getResourcesSecrets = (componentManifest: ComponentManifest) => {
  const resourcesSecrets = componentManifest?.secrets?.env?.fromResources;

  return resourcesSecrets
    ? (Object.keys(resourcesSecrets) as ResourceTypes[]).reduce<
        Record<string, string[]>
      >((acc, resourceFamilyKey) => {
        const resourceFamily = resourcesSecrets[resourceFamilyKey];

        if (!resourceFamily) return acc;

        Object.keys(resourceFamily).forEach(componentName => {
          acc[componentName] = resourceFamily[componentName].map(
            resource => resource.name,
          );
        });

        return acc;
      }, {})
    : {};
};

export const useComponentSecrets = ():
  | { error: Error }
  | { data: TabContent<string[] | Record<string, string[]>> } => {
  const entity = useDeploymentsStore(state => state.entity);
  const subscription = useDeploymentsStore(state => state.subscription);
  const productVersion = useDeploymentsStore(state => state.productVersion);

  if (!entity || !subscription || !productVersion)
    return {
      error: new Error(
        'Could not find component secrets because either component, subscription or productVersion is missing',
      ),
    };

  const componentManifestOrError =
    getComponentOrResourceAppliedManifest<ComponentManifest>(
      entity,
      subscription,
      productVersion,
    );

  if ('error' in componentManifestOrError) return componentManifestOrError;

  return {
    data: {
      common: { data: getCommonSecrets(componentManifestOrError) },
      resources: { data: getResourcesSecrets(componentManifestOrError) },
    },
  };
};
