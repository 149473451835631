import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { UserProducts } from './UserProducts';

export const UserProductsFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'UserProducts',
    component: UserProducts,
  }),
);
