import { Package2Icon } from 'backstage-plugin-icons-react';
import { RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { type MetricsMapFunction } from './useMetrics';
import { createMetrics } from '../../utils/createMetrics';

export const getProductsMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  _getEntityRoute,
) => {
  if (entity.spec?.type !== 'package')
    return {
      metrics: [
        {
          label: 'Products',
          error: new Error(
            'You cannot display products for a non-package entity',
          ),
        },
      ],
    };
  const productsReferences = getEntityRelations(entity, RELATION_DEPENDS_ON);
  if (productsReferences.length === 0)
    return {
      metrics: [
        {
          label: 'Products',
          error: new Error(
            `No products found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };
  const products = relatedEntities.filter(relatedEntity =>
    productsReferences.some(
      productReference => productReference.name === relatedEntity.metadata.name,
    ),
  );
  if (products.length !== productsReferences.length)
    return {
      metrics: [
        {
          label: 'Products',
          error: new Error(
            `At least one product entity not found for the entity ${entity.metadata.name}`,
          ),
        },
      ],
    };

  const metric = createMetrics(products.length, 'Products', Package2Icon);
  return { metrics: [metric] };
};
