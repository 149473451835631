import React from 'react';
import { RelationsContent } from '../content/RelationsContent';
import { OverviewContent } from '../content/OverviewContent';
import { TechDocsContent } from '../content/TechDocsContent';
import { EntityLayout } from '../entity-layout/EntityLayout';

export const DefaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/relations" title="Relations">
      {RelationsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
